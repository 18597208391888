import React from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { regex } from "pages/CreateOrder/helpers";
import useOrdersAPI from "api/OrdersAPI";

function ShareDialog(props) {
  const { open, handleClose, setBanner } = props;
  const { control, handleSubmit, setError, reset } = useForm();
  let { share_order } = useOrdersAPI();
  let urlParams = useParams();

  const onSubmit = (data) => {
    share_order(urlParams.orderId, data.email)
      .then(() => {
        reset();
        setBanner({
          message: "Order has been shared!",
          severity: "success",
        });
        handleClose();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (errorResponse.email) {
          setError("email", {
            message: errorResponse.email,
          });
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ textAlign: "left" }}
      fullWidth
      PaperProps={{ sx: { padding: "16px" } }}
    >
      <DialogTitle>Share Order?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Share this imaging order by email.
        </DialogContentText>
      </DialogContent>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1, px: "24px" }}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true, pattern: regex.email }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={!!fieldState.error}
              margin="normal"
              required
              fullWidth
              label="Email"
              helperText={
                !!fieldState.error &&
                (fieldState.error.message || "Not a valid email")
              }
            />
          )}
        />
        <Stack direction="row" justifyContent="flex-end" pt={2}>
          <Button onClick={handleClose}>Go Back</Button>
          <Button type="submit" variant="contained" autoFocus>
            Share
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default function ShareButton(props) {
  const { setBanner } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick} color="neutral">
        Share Order
      </Button>
      <ShareDialog
        open={open}
        handleClose={handleClose}
        setBanner={setBanner}
      />
    </div>
  );
}
