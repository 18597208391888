import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/muiTheme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { hotjar } from "react-hotjar";
import { init } from "@amplitude/analytics-browser";

if (process.env.NODE_ENV === "production") {
  /* Initialize for staging and production */
  Sentry.init({
    dsn: "https://fc45224bafd34d42a02731d15a6e637a@o1139131.ingest.sentry.io/6422345",
    integrations: [
      new BrowserTracing({
        tracingOrigins: [process.env.REACT_APP_PANDA_API_URL],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  /* Initialize for production only */
  if (process.env.REACT_APP_STAGE === "production") {
    hotjar.initialize(2972616, 6);
    init("898450ea97f5e8ec9e1e1f71fd3b59fa");
  }
} else {
  init("", "", { optOut: true });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
