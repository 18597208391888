import React from "react";
import {
  Alert,
  Button,
  Container,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { set_password } from "api/account";
import Copyright from "components/Copyright";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SetPassword() {
  const { control, handleSubmit, getValues, setError } = useForm();
  const [success, setSuccess] = React.useState(false);
  let query = useQuery();
  let navigate = useNavigate();

  const postSubmit = (error) => {
    if (error) {
      setError("newPassword", { type: "custom", message: error.message });
      return;
    }
    setSuccess(true);
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  const onSubmit = (data) => {
    set_password(query.get("token"), data.newPassword, postSubmit);
  };

  return (
    <Container sx={{ width: "80%" }}>
      {success && (
        <Alert severity="success">
          Password changed successfully! Routing you to login in 3...2...1
        </Alert>
      )}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Set your Password
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="New Password"
                type="password"
                helperText={fieldState.error?.message}
                autoComplete="new-password"
                autoFocus
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: {
                matches: (confirm) => confirm === getValues("newPassword"),
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                helperText={
                  fieldState.error?.type === "matches"
                    ? "Passwords do not match"
                    : ""
                }
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default SetPassword;
