import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

export default function FilterStatusCheckbox(props) {
  const { statusFilter, setStatusFilter } = props;
  const statuses = {
    Draft: "Draft",
    UnderReview: "Under Review",
    FaxSent: "Fax Sent",
    InProgress: "Fulfillment In Progress",
    ReportReady: "Results Ready",
    ActionRequired: "Action Required",
    Cancelled: "Cancelled",
  };

  const handleChange = (event) => {
    if (statusFilter === event.target.value) {
      setStatusFilter(null);
      return;
    }
    setStatusFilter(event.target.value);
  };

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component="legend">Filter by status:</FormLabel>
      <FormGroup>
        {Object.entries(statuses).map(([status, label]) => (
          <FormControlLabel
            key={status}
            control={
              <Checkbox
                checked={statusFilter === status}
                onChange={handleChange}
                value={status}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
