import { Stack, Tooltip, Chip, Typography } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

const StatusMessageTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "gray",
    fontSize: "14px",
    textAlign: "left",
    whiteSpace: "pre-line",
    maxWidth: "500px",
  },
}));

export function StatusChip(props) {
  const { status, statusMessage } = props;
  const translation = {
    Draft: 0,
    UnderReview: 1,
    FaxSent: 2,
    InProgress: 3,
    ReportReady: 4,
    ActionRequired: 5,
    Cancelled: 6,
    Inactive: 7,
    "Inactive:PatientUnreachable": 7,
    "Inactive:PatientNoShow": 7,
    "InProgress:Scheduled": 3,
    "ReportReady:Partial": 4,
    "ReportReady:DirectToReferrer": 4,
    "Cancelled:PatientDeclined": 6,
  };
  const steps = [
    "Draft",
    "Under Review",
    "Fax Sent",
    "Fulfillment In Progress",
    "Results Ready",
    "Action Required",
    "Cancelled",
    "Inactive",
  ];
  const variants = [
    "outlined",
    "filled",
    "filled",
    "filled",
    "filled",
    "filled",
    "outlined",
    "outlined",
  ];
  const colors = [
    "primary",
    "primary",
    "primary",
    "primary",
    "success",
    "error",
    "error",
    "neutral",
  ];

  return (
    <Stack direction="row">
      <Chip
        size="small"
        sx={{ borderRadius: "4px", letterSpacing: "0.4px" }}
        label={steps[translation[status]]}
        variant={variants[translation[status]]}
        color={colors[translation[status]]}
      />
      {statusMessage && (
        <StatusMessageTooltip title={statusMessage} placement="right" arrow>
          <StickyNote2Icon size="small" color="neutral" />
        </StatusMessageTooltip>
      )}
    </Stack>
  );
}

export function UrgencyChip(props) {
  const { urgency } = props;
  return (
    <Typography variant="body2" color={urgency === "Stat" ? "error" : "text"}>
      {urgency}
    </Typography>
  );
}
