import React from "react";
import lottie from "lottie-web";
import pandaWorking from "../AssignFacility/pandaWorking.json";
import { Typography, Stack } from "@mui/material";

export default function UploadingInProgress() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#panda-working"),
      animationData: pandaWorking,
    });
  }, []);
  return (
    <Stack
      direction="column"
      spacing={4}
      justifyContent="center"
      alignItems="center"
    >
      <div id="panda-working" style={{ width: 400, height: 400 }}></div>
      <Typography variant="h5">Creating your Imaging Order...</Typography>
    </Stack>
  );
}
