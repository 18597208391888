import { Box, Typography, Stack } from "@mui/material";
import { getLocaleDateTimeString } from "utils/parsing";

export function OrderMeta(props) {
  const { order } = props;
  const updated_at = new Date(order.updated_at);
  const created_at = new Date(order.created_at);
  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Box textAlign="left">
          <Typography variant="body2">
            Patient Name:{" "}
            <Typography component="span" variant="body1" fontWeight="bold">
              {order.patient.name}
            </Typography>
          </Typography>
          {order.procedures && order.procedures[0] && (
            <Typography variant="body2">
              Imaging Study:{" "}
              <Typography component="span" variant="body1" fontWeight="bold">
                {order.procedures[0].study.modality},{" "}
                {order.procedures[0].study.body_site},{" "}
                {order.procedures[0].study.contrast}
                {order.procedures[0].study.views}
              </Typography>
            </Typography>
          )}
          <Typography variant="body2">
            Urgency:{" "}
            <Typography
              component="span"
              variant="body1"
              fontWeight="bold"
              color={order.urgency === "Stat" ? "error" : ""}
            >
              {order.urgency}
            </Typography>
          </Typography>
        </Box>
        <Box textAlign="left">
          <Typography variant="body2" color="text.secondary">
            Last Updated: <b>{getLocaleDateTimeString(updated_at)}</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Created: {getLocaleDateTimeString(created_at)}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
