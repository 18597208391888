import React from "react";
import {
  Autocomplete,
  CircularProgress,
  Stack,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useOrdersAPI from "../../api/OrdersAPI";

function InsuranceCarrierFilterLabel(props) {
  const { carrier, handleClose } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="body2">
        Insurance Carrier:{" "}
        <Typography variant="body2" fontWeight="bold" component="span">
          {carrier}
        </Typography>
      </Typography>
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}

function InsuranceCarrierForm(props) {
  const { handleClose, setCarrierFilter } = props;
  const [carrierOptions, setCarrierOptions] = React.useState([]);
  const [carrier, setCarrier] = React.useState("");
  const { get_carriers } = useOrdersAPI();

  React.useEffect(() => {
    get_carriers(setCarrierOptions);
  }, [get_carriers]);

  const onAutocompleteChange = (event) => {
    setCarrier(event.target.textContent);
  };

  const onApply = () => {
    if (carrier !== "") {
      setCarrierFilter(carrier);
    }
  };

  return (
    <Stack direction="row" alignItems="flex-end" spacing={2}>
      <Typography variant="body1">Insurance Carrier: </Typography>
      <Autocomplete
        sx={{ width: 300 }}
        options={carrierOptions}
        loading={carrierOptions.length === 0}
        onChange={onAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Insurance Carrier"
            size="small"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {carrierOptions.length === 0 ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Button onClick={onApply}>Apply</Button>
      <Button color="neutral" onClick={handleClose}>
        Remove
      </Button>
    </Stack>
  );
}

export function InsuranceFilter(props) {
  const { carrier, setCarrierFilter, handleClose } = props;

  return (
    <>
      {!carrier && (
        <InsuranceCarrierForm
          setCarrierFilter={setCarrierFilter}
          handleClose={handleClose}
        />
      )}
      {carrier && (
        <InsuranceCarrierFilterLabel
          carrier={carrier}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
