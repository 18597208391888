import React from "react";
import { Box, Stack, Button, Typography, Divider } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadingInProgress from "../../components/SubmitOrder/UploadingInProgress";

function SectionTitle({ title }) {
  return (
    <Typography variant="h5" align="left" pb={2}>
      {title}
    </Typography>
  );
}

function UploadDocument(props) {
  const { file } = props;
  const [pages, setPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
  }

  return (
    <Document onLoadSuccess={onDocumentLoadSuccess} file={file}>
      {Array.apply(null, Array(pages))
        .map((x, i) => i + 1)
        .map((page) => (
          <Page key={page} pageNumber={page} />
        ))}
    </Document>
  );
}

export default function Confirmation(props) {
  const { state, patientName, handleBack, handleSubmit } = props;
  const { facility, urgency, uploadedFiles, isSelfPay, uploadingInProgress } =
    state;

  return uploadingInProgress ? (
    <UploadingInProgress />
  ) : (
    <Stack
      sx={{ paddingX: "64px", paddingY: "32px" }}
      spacing={8}
      divider={<Divider />}
      textAlign="left"
    >
      <Box>
        <SectionTitle title="Patient" />
        <Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
          {patientName}
        </Typography>
      </Box>
      {facility && (
        <Box>
          <SectionTitle title="Facility Selected" />
          <Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
            {facility.name}
          </Typography>
          <Typography variant="subtitle1" align="left">
            {`${facility.street_1} ${facility.street_2}, ${facility.city}, ${facility.state}`}
          </Typography>
        </Box>
      )}
      <Box>
        <SectionTitle title="Uploaded Documents" />
        {uploadedFiles.map(
          (doc, idx) =>
            doc && (
              <Accordion
                key={doc.name}
                variant="outlined"
                sx={{ marginBottom: "20px" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: "#ffffff",
                        background: "grey",
                        borderRadius: "50%",
                      }}
                    />
                  }
                >
                  <Typography variant="subtitle1">{doc.name}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  <UploadDocument key={`${doc.name}-${idx}`} file={doc} />
                </AccordionDetails>
              </Accordion>
            )
        )}
      </Box>
      <Box>
        <Typography variant="h6">
          Imaging Order Priority:<b>{` ${urgency}`}</b>
        </Typography>
        <Typography variant="h6">
          Is the patient self-pay?:<b>{` ${isSelfPay ? "Yes" : "No"}`}</b>
        </Typography>
      </Box>

      <Stack direction="row" justifyContent="space-between">
        <Button
          color="primary"
          startIcon={<ArrowBackIosNewIcon fontSize="inherit" />}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Order
        </Button>
      </Stack>
    </Stack>
  );
}
