import React from "react";
import {
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";

import { getLocaleDateTimeString } from "utils/parsing";
import { buildActivity, buildDetail } from "./helpers";

const TableCellHeader = styled(TableCell)({
  align: "left",
  fontWeight: "bold",
});

function OrderActivityTable(props) {
  const { orderLogsResponse, page, handleChangePage } = props;

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellHeader sx={{ width: "250px" }}>Date</TableCellHeader>
              <TableCellHeader>Activity</TableCellHeader>
              <TableCellHeader sx={{ minWidth: "200px" }}>
                Detail
              </TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderLogsResponse.results.map((entry) => (
              <TableRow
                key={entry.timestamp}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getLocaleDateTimeString(entry.timestamp)}
                </TableCell>
                <TableCell>{buildActivity(entry)}</TableCell>
                <TableCell>{buildDetail(entry)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25]}
        component="div"
        count={orderLogsResponse.count}
        rowsPerPage={25}
        page={page - 1}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from, to, count, page }) =>
          `Showing ${from}–${to} out of ${
            count !== -1 ? count : `more than ${to}`
          } events`
        }
      />
    </Paper>
  );
}
export default OrderActivityTable;
