import React from "react";
import { Stack, Typography, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { InsuranceFilter } from "./InsuranceFilter";
import StudyFilter from "./StudyFilter";
import NameFilter from "./NameFilter";

const StyledChip = styled(Chip)`
  &&:hover {
    background-color: #d3e3fd;
  }
`;

export default function FacilityFilters(props) {
  const { count, zipcode, updateSearchParams } = props;

  const [showCarrierFilter, setShowCarrierFilter] = React.useState(false);
  const [carrierFilter, setCarrierFilter] = React.useState("");

  const [showStudyFilter, setShowStudyFilter] = React.useState(false);
  const [studyFilter, setStudyFilter] = React.useState({});

  const [showNameFilter, setShowNameFilter] = React.useState(false);
  const [nameFilter, setNameFilter] = React.useState("");

  React.useEffect(() => {
    updateSearchParams({ insurance_carrier: carrierFilter });
  }, [carrierFilter, updateSearchParams]);

  React.useEffect(() => {
    updateSearchParams({ imaging_study: studyFilter.id });
  }, [studyFilter, updateSearchParams]);

  React.useEffect(() => {
    updateSearchParams({ name: nameFilter });
  }, [nameFilter, updateSearchParams]);

  const Title = () => (
    <Typography variant="h5" textAlign="left">
      There are{" "}
      <Typography component="span" fontWeight="bold" variant="h5">
        {count}
      </Typography>{" "}
      available facilities near{" "}
      <Typography component="span" fontWeight="bold" variant="h5">
        {zipcode}
      </Typography>
      .
    </Typography>
  );

  return (
    <Stack py={2} spacing={4}>
      <Title />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          Filter by:
        </Typography>
        {!showNameFilter && (
          <StyledChip
            label="Name"
            variant="outlined"
            onClick={() => setShowNameFilter(true)}
          />
        )}
        {!showStudyFilter && (
          <StyledChip
            label="Imaging Study"
            variant="outlined"
            onClick={() => setShowStudyFilter(true)}
          />
        )}
        {!showCarrierFilter && (
          <StyledChip
            label="Insurance Carrier"
            variant="outlined"
            onClick={() => setShowCarrierFilter(true)}
          />
        )}
      </Stack>
      {showNameFilter && (
        <NameFilter
          name={nameFilter}
          setNameFilter={setNameFilter}
          handleClose={() => {
            setShowNameFilter(false);
            setNameFilter("");
          }}
        />
      )}
      {showStudyFilter && (
        <StudyFilter
          study={studyFilter}
          setStudyFilter={setStudyFilter}
          handleClose={() => {
            setShowStudyFilter(false);
            setStudyFilter("");
          }}
        />
      )}
      {showCarrierFilter && (
        <InsuranceFilter
          carrier={carrierFilter}
          setCarrierFilter={setCarrierFilter}
          handleClose={() => {
            setShowCarrierFilter(false);
            setCarrierFilter("");
          }}
        />
      )}
      <Typography variant="body2" color="text.secondary" textAlign="left">
        Sorted by: Distance
      </Typography>
    </Stack>
  );
}
