import * as React from "react";
import { styled } from "@mui/material/styles";
import { Link, Typography, Chip, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box } from "@mui/system";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FacilityCard(props) {
  const { facility } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "100%", textAlign: "left" }} variant="outlined">
      <CardHeader
        title={facility.name}
        titleTypographyProps={{ variant: "subtitle1" }}
        sx={{ pb: 0 }}
      />
      <CardContent sx={{ py: 0 }}>
        <Typography color="text.secondary" variant="body2">
          {facility?.street_1} {facility?.street_2}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {facility?.city}, {facility?.state}
        </Typography>
        {facility.accepts_patient_insurance_carriers.length > 0 && (
          <Stack direction="column" spacing={1} pt={2}>
            {facility.accepts_patient_insurance_carriers.map((carrier) => (
              <Chip
                icon={<CheckCircleRoundedIcon />}
                label={`Accepts ${carrier}`}
                color="success"
                key={carrier}
              />
            ))}
          </Stack>
        )}
      </CardContent>
      <CardActions disableSpacing sx={{ py: 0, pl: 2 }}>
        <Typography color="text.secondary" variant="body2">
          {Math.round(parseFloat(facility?.distance_mi))} miles away
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box pb={2}>
            <Typography fontWeight={"bold"} variant="body2">
              Website:
            </Typography>
            <Link
              variant="body2"
              href={facility.website}
              target="_blank"
              rel="noopener"
            >
              {facility.website}
            </Link>
          </Box>
          {facility.opening_hours?.weekday_text?.length > 0 && (
            <Typography fontWeight={"bold"} variant="body2">
              Hours:
            </Typography>
          )}
          {facility.opening_hours?.weekday_text?.map((hours, index) => (
            <Typography key={index} variant="body2">
              {hours}
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
}
