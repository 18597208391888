import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

export default createTheme({
  palette: {
    background: {
      default: "#fafbfc",
      paper: "#fff",
    },
    primary: {
      main: blue[800],
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});
