import React from "react";
import Routes from "./components/AppRoutes";
import Sidebar from "./components/Sidebar";
import { useAuthContext } from "./context/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const { isAuthenticated } = useAuthContext();

  const mainStyle = {
    paddingLeft: isAuthenticated() ? 260 : 0,
  };

  return (
    <main style={mainStyle}>
      <CssBaseline />
      {isAuthenticated() && <Sidebar />}
      <Routes />
    </main>
  );
}

export default App;
