import React from "react";
import { Box, Container, Typography } from "@mui/material";
import OrdersTable from "../../components/OrdersTable";
import { useParams } from "react-router-dom";
import useOrdersAPI from "../../api/OrdersAPI";

function PatientOrders() {
  const [patientOrders, setPatientOrders] = React.useState({
    patient: {},
    orders: [],
  });
  let urlParams = useParams();
  let { get_patient_orders } = useOrdersAPI();

  React.useEffect(() => {
    get_patient_orders(urlParams.patientId, setPatientOrders);
  }, [urlParams.patientId, get_patient_orders]);

  return (
    <Container sx={{ width: "80%" }}>
      <Box sx={{ padding: "16px", marginBottom: "32px", marginTop: "32px" }}>
        <Typography variant="h3" component="div">
          {patientOrders.patient.name}'s Orders
        </Typography>
      </Box>
      <OrdersTable orders={patientOrders.orders} />
    </Container>
  );
}

export default PatientOrders;
