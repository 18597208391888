import { Stepper } from "@mui/material";
import { Step, StepLabel } from "../Stepper";

export default function StatusStepper(props) {
  const { status } = props;
  const steps = ["Select Facility", "Upload Documents", "Review & Submit"];
  const activeStep = status;

  return (
    <Stepper
      sx={{ width: 800, alignSelf: "center", paddingBottom: "32px" }}
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map((label, index) => {
        return (
          <Step key={label} completed={!!(index < activeStep)}>
            <StepLabel color="inherit">{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
