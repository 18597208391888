import axios from "axios";
import dayjs from "dayjs";
import { useAuthContext } from "../context/AuthContext";
import { refresh_tokens } from "api/account";

const baseURL = process.env.REACT_APP_PANDA_API_URL;

const useAxios = () => {
  const { tokens, setTokens, signOut, tokenClaims } = useAuthContext();

  const tokenIsExpired = () => {
    return dayjs.unix(tokenClaims?.exp).diff(dayjs()) < 1;
  };

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${tokens?.access}` },
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (!tokenIsExpired()) return config;

      try {
        let newTokens = await refresh_tokens(tokens.refresh);
        config.headers.Authorization = `Bearer ${newTokens.access}`;

        setTokens(newTokens);
      } catch (error) {
        signOut();
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 * Axios instance with baseURL set (no headers)
 */
export const axiosBasic = axios.create({ baseURL });

/**
 * Axios instance with baseURL set and authorization header set
 */
export default useAxios;
