import React from "react";
import {
  Alert,
  Button,
  Container,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { reset_password } from "api/account";
import Copyright from "components/Copyright";

function ResetPassword() {
  const { control, handleSubmit, setError } = useForm();
  const [success, setSuccess] = React.useState(false);

  const postSubmit = (error) => {
    if (error) {
      setError("email", { type: "custom", message: error.message });
      return;
    }
    setSuccess(true);
  };

  const onSubmit = (data) => {
    reset_password(data.email, postSubmit);
  };

  return (
    <Container sx={{ width: "80%" }}>
      {success && (
        <Alert severity="success">Reset password link has been sent!</Alert>
      )}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset your Password
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                helperText={fieldState.error?.message}
                autoComplete="email"
                autoFocus
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Reset Password Link
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default ResetPassword;
