import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Box, Button, Divider, Paper, Stack } from "@mui/material";
import UploadFileSharpIcon from "@mui/icons-material/UploadFileSharp";
import { styled } from "@mui/material/styles";
import useOrdersAPI from "api/OrdersAPI";

function FileAttachment(props) {
  const { attachment } = props;
  const [pages, setPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
  }

  return (
    <Paper variant="outlined" sx={{ width: 794 }}>
      <Stack
        alignItems="center"
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        <Button
          component="a"
          href={attachment.file}
          download
          sx={{ marginTop: 2 }}
        >
          Download copy
        </Button>
        <Document
          onLoadSuccess={onDocumentLoadSuccess}
          file={{
            url: attachment.file,
          }}
        >
          {Array.apply(null, Array(pages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page
                key={page}
                pageNumber={page}
                renderTextLayer={false}
                style={{
                  "& > *": { maxWidth: "100%" },
                }}
              />
            ))}
        </Document>
      </Stack>
    </Paper>
  );
}

const Input = styled("input")({
  display: "none",
});

export default function OrderDetailsTab(props) {
  const { order, setBanner } = props;
  const [isUploading, setIsUploading] = useState(false);
  const { start_file_attachment, upload_attachment } = useOrdersAPI();

  const handleUploadAdditionalFiles = async (event) => {
    setIsUploading(true);
    let { files } = event.target;

    try {
      let uploadingFiles = [];

      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);

        uploadingFiles.push(
          new Promise((resolve) => {
            start_file_attachment(order.id, ({ id: attachmentId }) => {
              upload_attachment(order.id, attachmentId, file, () => resolve());
            });
          })
        );
      }

      await Promise.all(uploadingFiles);

      setBanner({
        message: `Successfully uploaded ${files.length} additional file${
          files.length > 1 ? "s" : ""
        }`,
        severity: "success",
      });
    } catch (err) {
      if (process.env.REACH_APP_STAGE === "development") {
        console.error(err);
      }
      setBanner({
        message: "Failed to upload additional file(s): " + err.message,
        severity: "error",
      });
    }

    setIsUploading(false);
  };

  return (
    <Box py={4}>
      <label htmlFor="upload-files-button">
        <Input
          accept=".pdf"
          id="upload-files-button"
          type="file"
          multiple
          onChange={handleUploadAdditionalFiles}
        />
        <Button
          variant="contained"
          component="span"
          startIcon={<UploadFileSharpIcon />}
          size="large"
          sx={{ mb: 4 }}
          disabled={isUploading}
        >
          Upload Additional Files
        </Button>
      </label>

      {order.attachments.length > 0 && (
        <Stack alignItems="center" spacing={4}>
          {order.attachments.map((attachment) => (
            <FileAttachment key={attachment.id} attachment={attachment} />
          ))}
        </Stack>
      )}
    </Box>
  );
}
