import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function NameFilterLabel(props) {
  const { name, handleClose } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="body2">
        Name:{" "}
        <Typography variant="body2" fontWeight="bold" component="span">
          {name}
        </Typography>
      </Typography>
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}

function NameForm(props) {
  const { handleClose, setNameFilter } = props;
  const [name, setName] = React.useState("");

  const onInputChange = (event) => {
    setName(event.target.value);
  };

  const onApply = () => {
    setNameFilter(name);
  };

  return (
    <Stack direction="row" alignItems="flex-end" spacing={2}>
      <Typography variant="body1">Facility Name: </Typography>
      <TextField
        size="small"
        variant="standard"
        label="Facility Name"
        value={name}
        onChange={onInputChange}
      />
      <Button onClick={onApply}>Apply</Button>
      <Button color="neutral" onClick={handleClose}>
        Remove
      </Button>
    </Stack>
  );
}

export default function NameFilter(props) {
  const { name, setNameFilter, handleClose } = props;

  return (
    <>
      {!name && (
        <NameForm setNameFilter={setNameFilter} handleClose={handleClose} />
      )}
      {name && <NameFilterLabel name={name} handleClose={handleClose} />}
    </>
  );
}
