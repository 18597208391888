import {
  Stack,
  Button,
  Dialog,
  Box,
  TextField,
  DialogTitle,
  InputAdornment,
  Typography,
  colors,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { regex } from "pages/CreateOrder/helpers";
import useOrdersAPI from "api/OrdersAPI";

export default function InviteUserDialog(props) {
  const { institution, open, handleClose, refreshUsers, setBanner } = props;
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const { create_user } = useOrdersAPI();

  const onSubmit = (data) => {
    create_user(`${institution}-${data.username}`, data.email)
      .then(() => {
        reset();
        setBanner({
          message: "User has been invited!",
          severity: "success",
        });
        refreshUsers();
        handleClose();
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        if (errorResponse.email) {
          setError("email", {
            message: errorResponse.email,
          });
        }
        if (errorResponse.username) {
          setError("username", {
            message: errorResponse.username,
          });
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ textAlign: "left" }}
      fullWidth
      PaperProps={{ sx: { padding: "16px" } }}
    >
      <DialogTitle>Invite a User</DialogTitle>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1, px: "24px" }}
      >
        <Controller
          name="username"
          control={control}
          defaultValue=""
          rules={{ required: true, maxLength: 100, minLength: 3 }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={!!fieldState.error}
              margin="normal"
              required
              fullWidth
              label="Username"
              autoFocus
              helperText={
                !!fieldState.error &&
                (fieldState.error.message ||
                  "A username must be between 3 and 100 characters")
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {institution}-
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true, pattern: regex.email }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={!!fieldState.error}
              margin="normal"
              required
              fullWidth
              label="Email"
              helperText={
                !!fieldState.error &&
                (fieldState.error.message || "Not a valid email")
              }
            />
          )}
        />
        {errors.invite_failed && (
          <Typography variant="caption" color={colors.red[500]}>
            {errors.invite_failed.message}
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" pt={2}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" autoFocus>
            Invite User
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
