import React from "react";
import { Map, Marker, Overlay } from "pigeon-maps";
import { Box, Typography, Stack, Paper } from "@mui/material";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import PersonPinRoundedIcon from "@mui/icons-material/PersonPinRounded";
import maptilerProvider from "../../utils/maptilerProvider";
import useOrdersAPI from "../../api/OrdersAPI";
import FacilityCard from "./FacilityCard";
import NoFacilities from "./NoFacilities";

export function FacilitySelector(props) {
  const { patient, facilities } = props;

  let urlParams = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  let { set_order_facility } = useOrdersAPI();

  // Bring insurance matches to top
  // Default comparable insurance match to distance sort
  const sorted_facilities = React.useMemo(
    () =>
      facilities.sort((a, b) =>
        a?.accepts_patient_insurance_carriers?.length <
        b?.accepts_patient_insurance_carriers?.length
          ? 1
          : a?.accepts_patient_insurance_carriers?.length ===
              b?.accepts_patient_insurance_carriers?.length &&
            parseFloat(a?.distance_mi) > parseFloat(b?.distance_mi)
          ? 1
          : -1
      ),
    [facilities]
  );

  const [selectedFacility, setSelectedFacility] = React.useState(
    sorted_facilities[0]?.id
  );

  if (facilities === undefined || Object.keys(facilities).length === 0) {
    return <NoFacilities />;
  }

  const coordinate_pairs = facilities.map((facility) => [
    parseFloat(facility.lat),
    parseFloat(facility.lon),
  ]);

  const handleRadioChange = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleMarkerClick = (event) => {
    setSelectedFacility(event.payload);
  };

  const redirect = () => {
    const pathname = `/orders/${urlParams.orderId}`;
    if (pathname === location.pathname) {
      navigate(0);
    }
    navigate(pathname);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    set_order_facility(urlParams.orderId, selectedFacility, redirect);
  };

  let center_coordinates = [0, 0];
  coordinate_pairs.forEach((pair) => {
    center_coordinates[0] += pair[0];
    center_coordinates[1] += pair[1];
  });
  center_coordinates = center_coordinates.map(
    (coordinate) => coordinate / coordinate_pairs.length
  );

  return (
    <Paper variant="outlined" sx={{ padding: "20px", marginBottom: "32px" }}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Typography my={4} variant="h4">
            Choose a Facility
          </Typography>
          <Stack direction="row" alignItems="center" spacing={4}>
            <Box
              sx={{
                height: "600px",
                overflow: "auto",
              }}
            >
              <RadioGroup
                defaultValue={facilities[0].id}
                name="radio-buttons-group"
                value={selectedFacility}
                onChange={handleRadioChange}
              >
                {sorted_facilities.map((facility) => (
                  <FormControlLabel
                    key={facility.id}
                    value={facility.id}
                    control={<Radio />}
                    label={<FacilityCard facility={facility} />}
                    sx={{
                      borderRadius: "4px",
                      padding: "4px",
                      "&:hover": { backgroundColor: "#f0f0f0" },
                    }}
                  />
                ))}
              </RadioGroup>
            </Box>

            <Map
              provider={maptilerProvider}
              height={600}
              width={400}
              defaultCenter={center_coordinates}
              defaultZoom={10}
            >
              {facilities.map((facility) => (
                <Marker
                  key={facility.id}
                  width={50}
                  anchor={[parseFloat(facility.lat), parseFloat(facility.lon)]}
                  payload={facility.id}
                  color={facility.id === selectedFacility ? "blue" : "grey"}
                  onClick={handleMarkerClick}
                />
              ))}
              <Overlay
                width={50}
                anchor={[parseFloat(patient.lat), parseFloat(patient.lon)]}
                offset={[17, 35]}
              >
                <PersonPinRoundedIcon color="secondary" fontSize="large" />
              </Overlay>
            </Map>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="end" mt={4}>
            <Button
              component={Link}
              to={`/orders/${urlParams.orderId}`}
              variant="outlined"
              startIcon={<CloseIcon />}
              color="secondary"
            >
              Select Facility Later
            </Button>
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              color="primary"
              type="submit"
            >
              Send Order to Facility
            </Button>
          </Stack>
        </FormControl>
      </form>
    </Paper>
  );
}
