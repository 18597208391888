import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Button,
  Stack,
  Typography,
  TextField,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function PreferredFacilities(props) {
  const { preferredFacilities, onClick } = props;

  return (
    preferredFacilities.count > 0 && (
      <Stack spacing={2} alignSelf="left">
        <Typography variant="h6" color="text.secondary" textAlign="left">
          Your preferred facilities
        </Typography>
        <Stack spacing={2}>
          {preferredFacilities.results?.map((facility) => (
            <Paper
              variant="outlined"
              key={facility.id}
              sx={{
                "&:hover": { backgroundColor: "#D3E3FD", cursor: "pointer" },
              }}
              onClick={() => onClick(facility)}
            >
              <Stack
                direction="row"
                spacing={2}
                margin={2}
                textAlign="left"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography color="text.secondary" width="80%">
                  {facility.name}
                </Typography>
                <Typography color="text.secondary" width="80px">
                  {facility.state}, {facility.zip_code.split("-")[0]}
                </Typography>
              </Stack>
            </Paper>
          ))}
        </Stack>
      </Stack>
    )
  );
}

export default function StartSearch({
  updateSearchParams,
  preferredFacilities,

  /** Allow user to skip facility selection */
  allowSkip = true,

  /** Override default facility select handler */
  handleFacilitySelect = undefined,
}) {
  const [zipcode, setZipcode] = React.useState("");
  const [inputProps, setInputProps] = React.useState({});

  let navigate = useNavigate();

  const handleSearchChange = (event) => {
    setZipcode(event.target.value);
  };

  const onSearch = (event) => {
    event.preventDefault();
    if (zipcode.toString().length === 5) {
      setInputProps({ error: false, helperText: "" });
      updateSearchParams({ nearby: zipcode });
      return;
    }
    setInputProps({ error: true, helperText: "Not a valid ZIP code" });
  };

  const handleSkip = () => {
    navigate(`/facilities/skipped/submit-order`);
  };

  const handlePreferredFacilityClick = async (facility) => {
    if (handleFacilitySelect) {
      await handleFacilitySelect(facility.id);
      return;
    }

    navigate(`/facilities/${facility.id}/submit-order`);
  };

  return (
    <Stack
      alignSelf="center"
      py={2}
      spacing={8}
      id="search__start"
      width="100%"
    >
      <Stack
        component="form"
        direction="row"
        justifyContent="flex-end"
        onSubmit={onSearch}
      >
        <TextField
          sx={{ "& fieldset": { borderRadius: "4px 0px 0px 4px" } }}
          label="Search by ZIP code"
          value={zipcode}
          onChange={handleSearchChange}
          type="number"
          {...inputProps}
          fullWidth
        />
        <Button
          variant="contained"
          disableElevation
          sx={{ px: 6, borderRadius: "0px 0px 0px 0px", height: "56px" }}
          startIcon={<SearchIcon />}
          type="submit"
        >
          Search
        </Button>
        {allowSkip && (
          <Button
            variant="outlined"
            disableElevation
            sx={{ px: 4, borderRadius: "0px 4px 4px 0px", height: "56px" }}
            type="button"
            onClick={handleSkip}
          >
            Skip
          </Button>
        )}
      </Stack>
      <PreferredFacilities
        preferredFacilities={preferredFacilities}
        onClick={handlePreferredFacilityClick}
      />
    </Stack>
  );
}
