import React from "react";
import {
  Box,
  Stack,
  Paper,
  Button,
  Divider,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import multiDownload from "multi-download";
import { TabPanel } from "components/Tabs";

function ResultDocument(props) {
  const { report } = props;
  const [pages, setPages] = React.useState(null);
  const [tab, setTab] = React.useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Paper variant="outlined" sx={{ width: 794 }}>
      <Stack alignItems="center" spacing={2}>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: 2, paddingX: 4 }}
        >
          <Button component="a" href={report.report_file} download>
            Download copy
          </Button>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="View as PDF" />
            <Tab label="View as Text" />
          </Tabs>
        </Stack>
        <Divider orientation="horizontal" flexItem />
        <TabPanel value={tab} index={0}>
          <Document
            onLoadSuccess={onDocumentLoadSuccess}
            file={{
              url: report.report_file,
            }}
          >
            {Array.apply(null, Array(pages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page key={page} pageNumber={page} />
              ))}
          </Document>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Box paddingX={8}>
            <Typography
              variant="body1"
              whiteSpace="pre-line"
              py={2}
              textAlign="left"
            >
              {report.report_file_text}
            </Typography>
          </Box>
        </TabPanel>
      </Stack>
    </Paper>
  );
}

function ResultDocuments(props) {
  const { reports } = props;

  return (
    <Stack spacing={8} alignItems="center">
      {reports.map((report) => (
        <ResultDocument key={report.id} report={report} />
      ))}
    </Stack>
  );
}

function ResultsEmptyState() {
  return (
    <Paper variant="outlined">
      <Typography variant="h6" color="text.secondary" py={4}>
        Results have not been uploaded.
      </Typography>
    </Paper>
  );
}

function DownloadAll(props) {
  const { reports } = props;
  const handleClick = () => {
    multiDownload(reports.map((report) => report.report_file));
  };
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      component="span"
      startIcon={<DownloadIcon />}
      size="large"
      sx={{ mb: 4 }}
    >
      Download All Results
    </Button>
  );
}

export default function ResultsTab(props) {
  const { reports } = props;

  return (
    <Box py={4}>
      {reports.length > 0 && (
        <>
          <DownloadAll reports={reports} />
          <ResultDocuments reports={reports} />
        </>
      )}
      {reports.length === 0 && <ResultsEmptyState />}
    </Box>
  );
}
