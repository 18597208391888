import React from "react";
import { Stack, Button, IconButton } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { selectLabelOffset } from "../../pages/CreateOrder/helpers";
import useOrdersAPI from "../../api/OrdersAPI";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

function StudyForm(props) {
  const { handleClose, setStudyFilter } = props;
  const [studyOptions, setStudyOptions] = React.useState([]);
  const { handleSubmit, control, setValue } = useForm();
  const { get_imaging_studies } = useOrdersAPI();

  const study = useWatch({ control, name: "study" });

  React.useEffect(
    () => get_imaging_studies(setStudyOptions),
    [get_imaging_studies]
  );

  React.useEffect(() => {
    /** Save the study id for creating order **/
    if (study?.modality && study?.body_site && study?.contrast_or_views) {
      let match = studyOptions.find(
        (s) =>
          s.modality === study.modality &&
          s.body_site === study.body_site &&
          (s.contrast === study.contrast_or_views ||
            s.views === study.contrast_or_views)
      );

      if (match) setValue("study.id", match.id);
    }
  }, [
    study?.modality,
    study?.body_site,
    study?.contrast_or_views,
    studyOptions,
    setValue,
  ]);

  const onSubmit = (data) => {
    setStudyFilter(data.study);
  };

  const getModalityOptions = () => {
    if (!studyOptions?.length) return [];

    let modalitys = studyOptions.map((study) => study.modality);
    return [...new Set(modalitys)].sort();
  };

  const getBodySiteOptions = (modality) => {
    if (!studyOptions?.length || !modality) return [];

    let bodySites = studyOptions
      .filter((study) => study.modality === modality)
      .map((study) => study.body_site);
    return [...new Set(bodySites)].sort();
  };

  const getContrastOrViewOptions = (modality, bodySite) => {
    if (!studyOptions?.length || !modality || !bodySite) return [];

    let options = studyOptions
      .filter(
        (study) => study.modality === modality && study.body_site === bodySite
      )
      .map((study) => study.contrast || study.views);

    return [...new Set(options)].sort();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="patient-form">
      <Stack direction="row" alignItems="flex-end" spacing={4}>
        <Typography
          variant="body1"
          align="left"
          sx={{ display: "inline-block", whiteSpace: "nowrap" }}
        >
          Imaging Study:
        </Typography>
        <Controller
          name="study.modality"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel id="modality-label" style={{ ...selectLabelOffset }}>
                Imaging Modality*
              </InputLabel>
              <Select
                {...field}
                variant="standard"
                labelId="modality-label"
                required
                error={!!fieldState.error}
                style={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                {getModalityOptions().map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="study.body_site"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel id="body-site-label" style={{ ...selectLabelOffset }}>
                Body Site*
              </InputLabel>
              <Select
                {...field}
                variant="standard"
                labelId="body-site-label"
                required
                error={!!fieldState.error}
                style={{ display: "flex", flexGrow: 1 }}
              >
                {getBodySiteOptions(study?.modality).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="study.contrast_or_views"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel id="contrast-label" style={{ ...selectLabelOffset }}>
                Contrast or Views*
              </InputLabel>
              <Select
                {...field}
                variant="standard"
                labelId="contrast-label"
                required
                error={!!fieldState.error}
                style={{ display: "flex", flexGrow: 1 }}
              >
                {getContrastOrViewOptions(
                  study?.modality,
                  study?.body_site
                ).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Button type="submit">Apply</Button>
        <Button color="neutral" onClick={handleClose}>
          Remove
        </Button>
      </Stack>
    </form>
  );
}

function StudyFilterLabel(props) {
  const { study, handleClose } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="body2">
        Imaging Study:{" "}
        <Typography variant="body2" fontWeight="bold" component="span">
          {study.modality}, {study.body_site}, {study.contrast_or_views}
        </Typography>
      </Typography>
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}

export default function StudyFilter(props) {
  const { study, handleClose, setStudyFilter } = props;
  return (
    <>
      {study?.id && (
        <StudyFilterLabel handleClose={handleClose} study={study} />
      )}
      {!study?.id && (
        <StudyForm handleClose={handleClose} setStudyFilter={setStudyFilter} />
      )}
    </>
  );
}
