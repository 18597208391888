import React, { useEffect, useReducer } from "react";
import OrdersTable from "components/OrdersTable";
import useOrdersAPI from "api/OrdersAPI";
import PageContainer from "components/PageContainer";
import SearchField from "components/SearchField";
import { useSearchParams } from "react-router-dom";

const defaultState = {
  loading: true,
  orders: {
    count: 0,
    results: [],
  },

  search: null,
  status: undefined,
  page: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING START":
      return { ...state, loading: true };
    case "UPDATE STATE":
      return { ...state, ...action.payload, loading: false };
    default:
      throw Error("Invalid Action");
  }
};

function Dashboard() {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const { get_all_orders } = useOrdersAPI();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    /*
     * Unescape the "+" from setSearchParams, which is used to escape spaces " ".
     */
    let search = searchParams.get("s")?.replace("+", " ");

    let status = searchParams.get("f");

    let page = Number(searchParams.get("p")) || 1;

    get_all_orders({ page, search, status }).then((orders) =>
      dispatch({
        type: "UPDATE STATE",
        payload: { orders, search, status, page },
      })
    );
  }, [searchParams, get_all_orders, dispatch]);

  const updateParams = (name, value) => {
    if (value) {
      searchParams.set(name, value);

      if (name !== "p") {
        /*
         * Reset page to first page when Search or Filter is updated to prevent
         * page out-of-range drama in case the consequent call to get_all_orders()
         * returns less pages of orders.
         */
        searchParams.set("p", 1);
      }
    } else {
      searchParams.delete(name);
    }
    setSearchParams(searchParams);
  };

  return (
    <PageContainer header="Imaging Order History">
      <SearchField
        label="Search by patient name or facility name"
        onSubmit={({ search }) => updateParams("s", search)}
        parentValue={state.search || ""}
      />
      {!state.loading && (
        <OrdersTable
          page={state.page}
          handleChangePage={(_, newPage) => updateParams("p", newPage + 1)}
          statusFilter={state.status}
          setStatusFilter={(filter) => updateParams("f", filter)}
          orders={state.orders.results}
          count={state.orders.count}
        />
      )}
    </PageContainer>
  );
}

export default Dashboard;
