import React from "react";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { Box, Stack, TablePagination, Radio } from "@mui/material";
import maptilerProvider from "../../utils/maptilerProvider";
import FacilityCard from "../AssignFacility/FacilityCard";

const facilityIsValid = (facility) => !!facility?.lat;

export default function FacilityExplorerViewer(props) {
  const {
    facilities,
    selectedFacility,
    setSelectedFacility,
    searchPage,
    updateSearchParams,
  } = props;

  const [center, setCenter] = React.useState([
    /* Starting location is Panda HQ */
    37.79012389429849, -122.40087505727408,
  ]);
  const [zoom, setZoom] = React.useState(9);

  React.useEffect(() => {
    if (facilityIsValid(selectedFacility)) {
      setCenter([selectedFacility.lat, selectedFacility.lon]);
      setSelectedFacility(selectedFacility);
    }
  }, [selectedFacility, setSelectedFacility]);

  React.useEffect(() => {
    if (facilities.count > 0) {
      setCenter([facilities.results[0].lat, facilities.results[0].lon]);
    }
  }, [facilities]);

  const handleRadioChange = (facilityId) => {
    const facility = facilities?.results.find((f) => f.id === facilityId);
    setSelectedFacility(facility);
    setZoom(12);
  };

  const handleMarkerClick = (event) => {
    setSelectedFacility(event.payload);
  };

  const handleChangePage = (event, newPage) => {
    updateSearchParams({ page: newPage + 1 });
  };

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={4}>
        <Box
          sx={{
            height: "600px",
            width: "100%",
            overflow: "auto",
          }}
        >
          {facilities?.results.map((facility) => (
            <Stack
              direction="row"
              key={facility.id}
              sx={{
                borderRadius: "4px",
                padding: "4px",
                "&:hover": { backgroundColor: "#d3e3fd", cursor: "pointer" },
              }}
              onClick={() => handleRadioChange(facility.id)}
            >
              <Radio
                checked={selectedFacility?.id === facility.id}
                onChange={(event) => handleRadioChange(event.target.value)}
                value={facility.id}
              />
              <FacilityCard facility={facility} />
            </Stack>
          ))}
        </Box>

        <Map
          provider={maptilerProvider}
          height={600}
          defaultZoom={9}
          center={center}
          zoom={zoom}
          onBoundsChanged={({ center, zoom }) => {
            setCenter(center);
            setZoom(zoom);
          }}
        >
          <ZoomControl />
          {facilities?.results.map((facility) => (
            <Marker
              key={facility.id}
              width={50}
              anchor={[parseFloat(facility.lat), parseFloat(facility.lon)]}
              payload={facility}
              color={facility.id === selectedFacility?.id ? "blue" : "grey"}
              onClick={handleMarkerClick}
              style={{ zIndex: facility.id === selectedFacility?.id ? 1 : 0 }}
            />
          ))}
        </Map>
      </Stack>
      <TablePagination
        rowsPerPageOptions={[25]}
        component="div"
        count={facilities?.count}
        rowsPerPage={25}
        page={searchPage - 1}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from, to, count, page }) =>
          `Showing ${from}–${to} out of ${
            count !== -1 ? count : `more than ${to}`
          } facilities`
        }
      />
    </Stack>
  );
}
