import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Divider, Button, Stack, Typography, Tab, Tabs } from "@mui/material";
import useOrdersAPI from "api/OrdersAPI";
import UsersTable from "components/UsersTable";
import FeedbackBanner from "components/FeedbackBanner";
import InviteUserDialog from "components/InviteUserDialog";
import UserAuditLogTable from "components/AuditLogTables/UserAuditLogTable";
import PageContainer from "components/PageContainer";
import { TabPanel } from "components/Tabs";
import Link from "components/Link";

function UserManagement() {
  const [users, setUsers] = React.useState({
    count: 0,
    results: [],
  });

  const [me, setMe] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);
  const [banner, setBanner] = React.useState({
    message: "",
    severity: "success",
  });

  const { get_all_users, get_my_user } = useOrdersAPI();

  React.useEffect(() => {
    get_all_users().then((responseData) => setUsers(responseData));
  }, [get_all_users]);

  React.useEffect(() => {
    get_my_user().then((responseData) => setMe(responseData));
  }, [get_my_user]);

  const refreshUsers = () =>
    get_all_users().then((responseData) => setUsers(responseData));

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const header = (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h4">User Management</Typography>
      {me.is_superuser && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenInviteDialog(true)}
        >
          Invite New User
        </Button>
      )}
    </Stack>
  );

  return (
    <PageContainer header={header}>
      <FeedbackBanner banner={banner} />
      <Stack spacing={2} sx={{ marginTop: "128px" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Users" />
          <Tab label="Audit Log" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <UsersTable
            me={me}
            users={users}
            setBanner={setBanner}
            refreshUsers={refreshUsers}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <UserAuditLogTable />
        </TabPanel>
      </Stack>
      <InviteUserDialog
        institution={me.institution}
        open={openInviteDialog}
        handleClose={() => setOpenInviteDialog(false)}
        setBanner={setBanner}
        refreshUsers={refreshUsers}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Link to="/tos" label="Terms of Service" />
        <Link to="/baa" label="BAA" />
      </Stack>
    </PageContainer>
  );
}
export default UserManagement;
