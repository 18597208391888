import React from "react";
import lottie from "lottie-web";
import pandaWorking from "./pandaWorking.json";
import { Typography, Stack, Paper } from "@mui/material";

export default function NoFacilities() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#panda-working"),
      animationData: pandaWorking,
    });
  }, []);
  return (
    <Paper sx={{ padding: 8 }} variant="outlined">
      <Stack
        direction="column"
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <div id="panda-working" style={{ width: 400, height: 400 }}></div>
        <Typography variant="h5">
          The Imaging Panda team is finding facilities to accommodate this
          order.
        </Typography>
      </Stack>
    </Paper>
  );
}
