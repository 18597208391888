import { get_my_user } from "api/account";
import jwt_decode from "jwt-decode";

export const localStorageLoadTokens = () => {
  return localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;
};

export const localStorageSaveTokens = (tokens) => {
  localStorage.setItem("auth", JSON.stringify(tokens));
};

export const localStorageClearTokens = () => {
  localStorage.removeItem("auth");
};

export const readTokenClaims = (token) => (token ? jwt_decode(token) : null);

export const fetchUserAccount = (token) => (token ? get_my_user(token) : null);
