import React, { useEffect, useReducer } from "react";
import { sign_user_in } from "api/account";
import { refresh_tokens } from "api/account";
import {
  localStorageLoadTokens,
  localStorageSaveTokens,
  readTokenClaims,
  fetchUserAccount,
  localStorageClearTokens,
} from "./helpers";

const defaultAuthState = {
  loading: true,
  tokens: null,
  tokenClaims: null,
  user: null,
};

const AuthContext = React.createContext({
  isAdmin: null,
  isAuthenticated: null,
  isMember: null,
  isRestricted: null,
  setTokens: null,
  signIn: null,
  signOut: null,
  tokens: null,
  user: null,
  tokenClaims: null,
});

/**
 *
 * Middleware for dispatch that can perform async operations before calling
 * the actual dispatch.
 *
 * @returns {void}
 */
const _dispatch = (state, action) => {
  switch (action.type) {
    case "UPDATE STATE": {
      return { ...state, ...action.payload };
    }

    default:
      throw Error("Invalid action");
  }
};

/**
 *
 * Middleware for dispatch that can perform async operations before calling
 * the actual dispatch.
 *
 * @returns {Function}
 */
const asyncDispatchMiddleware = (dispatch) => {
  return async (action) => {
    switch (action.type) {
      case "LOAD LOCAL STORAGE AUTH TOKENS": {
        let tokens = localStorageLoadTokens();
        let tokenClaims, user;

        if (tokens) {
          tokens = await refresh_tokens(tokens.refresh).catch(() => null);
          tokenClaims = readTokenClaims(tokens?.access);
          user = await fetchUserAccount(tokens?.access);

          localStorageSaveTokens(tokens);
        }

        dispatch({
          type: "UPDATE STATE",
          payload: {
            tokens,
            tokenClaims,
            user,
            loading: false,
          },
        });
        break;
      }

      case "SIGN USER IN": {
        const { username, password } = action.payload;

        const tokens = await sign_user_in(username, password);
        const tokenClaims = readTokenClaims(tokens?.access);
        const user = await fetchUserAccount(tokens?.access);

        localStorageSaveTokens(tokens);

        dispatch({
          type: "UPDATE STATE",
          payload: {
            tokens,
            tokenClaims,
            user,
            loading: false,
          },
        });
        break;
      }

      case "UPDATE TOKENS": {
        const { tokens } = action.payload;

        const tokenClaims = readTokenClaims(tokens?.access);
        const user = await fetchUserAccount(tokens?.access);

        localStorageSaveTokens(tokens);

        dispatch({
          type: "UPDATE STATE",
          payload: {
            tokens,
            tokenClaims,
            user,
            loading: false,
          },
        });
        break;
      }

      case "SIGN USER OUT": {
        const tokens = null;
        const tokenClaims = null;
        const user = null;

        localStorageClearTokens();

        dispatch({
          type: "UPDATE STATE",
          payload: {
            tokens,
            tokenClaims,
            user,
            loading: false,
          },
        });
        break;
      }

      default:
        throw Error("Invalid action");
    }
  };
};

export const AuthProvider = ({ children }) => {
  let [state, dispatch] = useReducer(_dispatch, defaultAuthState);
  dispatch = asyncDispatchMiddleware(dispatch);

  useEffect(() => {
    dispatch({
      type: "LOAD LOCAL STORAGE AUTH TOKENS",
    });
  }, []);

  let signIn = async (username, password) => {
    try {
      await dispatch({
        type: "SIGN USER IN",
        payload: { username, password },
      });
    } catch (error) {
      throw new Error("Login failed, please try again");
    }
  };

  let signOut = async () => {
    dispatch({ type: "SIGN USER OUT" });
  };

  let isAuthenticated = () => !!state.user;
  let isAdmin = () => state.tokenClaims?.role === "admin";
  let isMember = () => state.tokenClaims?.role === "member";
  let isRestricted = () => state.tokenClaims?.role === "restricted";

  const setTokens = (tokens) => {
    dispatch({
      type: "UPDATE TOKENS",
      payload: { tokens },
    });
  };

  let value = {
    isAdmin,
    isAuthenticated,
    isMember,
    isRestricted,
    signIn,
    signOut,
    setTokens,
    tokens: state.tokens,
    tokenClaims: state.tokenClaims,
    user: state.user,
  };

  return (
    <AuthContext.Provider value={value}>
      {state.loading ? null : children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return React.useContext(AuthContext);
}

export default AuthContext;
