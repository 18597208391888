import { Step as MuiStep, StepLabel as MuiStepLabel } from "@mui/material";
import { blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

export const Step = styled(MuiStep)`
  .MuiStepIcon-root {
    width: 32px;
    height: 32px;
  }

  .MuiStepConnector-root {
    top: 16px;
  }
`;

export const StepLabel = styled(MuiStepLabel)`
  .MuiStepLabel-label {
    font-size: 1rem;

    &.Mui-completed {
      color: ${blue[800]};
    }

    &.Mui-active {
      color: ${blue[800]};
    }
  }
`;
