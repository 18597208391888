import * as React from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Stack,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";

import useOrdersAPI from "api/OrdersAPI";
import { useAuthContext } from "context/AuthContext";
import { getLocaleDateTimeString } from "utils/parsing";

function Bubble(props) {
  const { chat, user } = props;

  const created_at = new Date(chat.created_at);

  const baseStyle = {
    width: "60%",
    padding: 2,
    paddingBottom: 2,
    borderRadius: 3,
  };

  const fromMeStyle = {
    ...baseStyle,
    background: blue[200],
    borderBottomRightRadius: 0,
    alignSelf: "end",
  };

  const receivedStyle = {
    ...baseStyle,
    borderBottomLeftRadius: 0,
    background: grey[100],
  };

  const isFromMe = user.id === chat.sender.id;
  const fromUser =
    chat.sender.institution === "imagingpanda"
      ? "Imaging Panda Support Team"
      : chat.sender.username;

  return (
    <Stack direction="column" spacing={1}>
      <Stack spacing={1} sx={isFromMe ? fromMeStyle : receivedStyle}>
        <Typography variant="body1" textAlign="left" sx={{ opacity: "75%" }}>
          {chat.message}
        </Typography>

        <Typography variant="body2" textAlign="left" color="text.secondary">
          Sent on {getLocaleDateTimeString(created_at)}
        </Typography>
      </Stack>
      {!isFromMe && (
        <Typography variant="body2" textAlign="left" color="text.secondary">
          {fromUser}
        </Typography>
      )}
    </Stack>
  );
}

function Discussion(props) {
  const { discussion, user } = props;

  const EmptyState = (
    <Typography variant="body2" color="text.secondary">
      We’re here to help. If you have any questions regarding this order or if
      something doesn’t look right, please send us a message below.
    </Typography>
  );

  return (
    <Paper variant="outlined" sx={{ marginX: 4, marginY: 4, paddingY: 4 }}>
      {discussion.length === 0 && EmptyState}
      <Stack direction="column" spacing={2} paddingX={2}>
        {discussion.map((chat) => (
          <Bubble key={chat.id} chat={chat} user={user} />
        ))}
      </Stack>
    </Paper>
  );
}

function SendChat(props) {
  const { setBanner } = props;
  const [message, setMessage] = React.useState("");
  const { send_chat_message } = useOrdersAPI();
  let { orderId } = useParams();

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!message) {
      return;
    }
    send_chat_message(orderId, message).then(() => {
      setMessage("");
      setBanner({ message: "" });
    });
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      mx={4}
      component="form"
      onSubmit={handleSubmit}
    >
      <TextField
        sx={{ "& fieldset": { borderRadius: 1 } }}
        label="Write a message"
        value={message}
        onChange={handleMessageChange}
        type="text"
        fullWidth
      />
      <Button
        type="submit"
        variant="contained"
        startIcon={
          <SendIcon sx={{ transform: "rotate(-30deg)", top: "-20px" }} />
        }
        sx={{ width: "120px" }}
      >
        Send
      </Button>
    </Stack>
  );
}

export default function SupportTab(props) {
  const { discussion, setBanner } = props;
  const { user } = useAuthContext();

  React.useEffect(() => {}, []);

  return (
    <Box py={4}>
      <Paper variant="outlined" sx={{ paddingY: 4 }}>
        <Discussion discussion={discussion} user={user} />
        <SendChat setBanner={setBanner} />
      </Paper>
    </Box>
  );
}
