import React from "react";
import {
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import { startCase } from "lodash";

import useOrdersAPI from "api/OrdersAPI";
import { getLocaleDateTimeString } from "utils/parsing";

const TableCellHeader = styled(TableCell)({
  align: "left",
  fontWeight: "bold",
});

function build_action_string(entry) {
  switch (entry.action) {
    case "update":
      if ("last_login" in entry.changes) {
        return (
          <span>
            <b>{entry.object_repr}</b> logged in
          </span>
        );
      }
      return (
        <span>
          <b>{entry.object_repr}</b> changed their{" "}
          {Object.keys(entry.changes)
            .map((change) => startCase(change).toLowerCase())
            .join(", ")}
        </span>
      );
    case "create":
      return (
        <span>
          <b>{entry.changes.username[1]}</b>({entry.changes.email[1]}) was
          invited
        </span>
      );
    case "delete":
      return (
        <span>
          <b>{entry.changes.username[0]}</b>({entry.changes.email[0]}) was
          deleted
        </span>
      );
    default:
      return <span>{entry.action}</span>;
  }
}

function UserAuditLogTable() {
  const [userLogsResponse, setUserLogs] = React.useState({
    count: 0,
    results: [],
  });
  const [page, setPage] = React.useState(1);

  const { get_user_logs } = useOrdersAPI();

  React.useEffect(() => {
    get_user_logs({ page }).then((responseData) => setUserLogs(responseData));
  }, [get_user_logs, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellHeader sx={{ minWidth: "250px" }}>Date</TableCellHeader>
              <TableCellHeader sx={{ minWidth: "100px" }}>
                Actor
              </TableCellHeader>
              <TableCellHeader sx={{ minWidth: "600px" }}>
                Action
              </TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {userLogsResponse.results.map((entry) => (
              <TableRow
                key={entry.timestamp}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getLocaleDateTimeString(entry.timestamp)}
                </TableCell>
                <TableCell>{entry?.actor?.username || "system"}</TableCell>
                <TableCell>{build_action_string(entry)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25]}
        component="div"
        count={userLogsResponse.count}
        rowsPerPage={25}
        page={page - 1}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from, to, count, page }) =>
          `Showing ${from}–${to} out of ${
            count !== -1 ? count : `more than ${to}`
          } events`
        }
      />
    </Paper>
  );
}
export default UserAuditLogTable;
