const toE164Format = (phone) => {
  return "+1" + phone.replace(/\D/g, "");
};

const formatDOB = (dob) => {
  let [mm, dd, yyyy] = dob.split("/");
  return [yyyy, mm, dd].join("-");
};

export const structureOrder = (obj) => {
  let order = JSON.parse(JSON.stringify(obj));

  order.patient.primary_phone = toE164Format(order.patient.primary_phone);
  order.patient.dob = formatDOB(order.patient.dob);
  order.patient.insurances = new Array(order.insurance);

  order.urgency = order.is_stat ? "Stat" : "Routine";
  order.procedures = new Array({
    study_id: order.study.id,
    diagnoses: [order.diagnosis],
  });

  order.requester.fax = toE164Format(order.requester.fax);
  order.requester.phone = toE164Format(order.requester.phone);

  return order;
};

/** Correct the label offset for the input selects */
export const selectLabelOffset = { left: -14 };

// prettier-ignore
export const allStates = [ "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY" ];

// prettier-ignore
export const degreeOptions = [ "CC", "DC", "DDS", "DNP", "DO", "DPO", "MD", "NP", "PA" ];

export const regex = {
  date: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,

  email:
    // Source: https://www.emailregex.com/
    //
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  icd10: /^([a-tA-T]|[v-zV-Z])\d[a-zA-Z0-9](\.[a-zA-Z0-9]{1,4})?$/,

  npi: /^\d{10}$/,

  phone: /^\(\d{3}\) \d{3}-\d{4}$/,

  stateAbbr: /^\w{2}$/,

  zipCode: /^\d{5}$/,
};
