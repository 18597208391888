import * as React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import useOrdersAPI from "api/OrdersAPI";
import OrderActivityTable from "components/AuditLogTables/OrderActivityTable";

export default function ActivityLogTab() {
  const [orderLogsResponse, setOrderLogs] = React.useState({
    count: 0,
    results: [],
  });
  const [page, setPage] = React.useState(1);

  const { get_order_logs_by_id } = useOrdersAPI();
  let { orderId } = useParams();

  React.useEffect(() => {
    get_order_logs_by_id(orderId, { page }).then((responseData) =>
      setOrderLogs(responseData)
    );
  }, [get_order_logs_by_id, page, orderId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <Box py={4}>
      <OrderActivityTable
        orderLogsResponse={orderLogsResponse}
        page={page}
        handleChangePage={handleChangePage}
      />
    </Box>
  );
}
