import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Chip, Stack } from "@mui/material";
import OrderDetailsTab from "./OrderDetailsTab";
import FacilityTab from "./FacilityTab";
import ResultsTab from "./ResultsTab";
import { TabPanel } from "components/Tabs";
import ActivityLogTab from "./ActivityLogTab";
import SupportTab from "./SupportTab";
import { useSearchParams } from "react-router-dom";

const tabs = ["details", "facility", "results", "logs", "support"];

export default function OrderTabs(props) {
  const { order, setBanner } = props;
  const [value, setValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    /*
     * Activate the tab based on query string parameter `tab`. Defaults to the
     * Order Details tab when query string tab is unknown.
     */
    let tabIndex = tabs.indexOf(searchParams.get("tab"));

    if (tabIndex < 0) {
      setSearchParams(`tab=details`);
      return;
    }

    setValue(tabIndex);
  }, [searchParams, setSearchParams, setValue]);

  const handleChange = (event, newValue) => {
    /*
     * Update URLs search params to keep record or selected tabs in history.
     * The useEffect above is triggered upon updating search params and also
     * enables back button functionality in the browser.
     */
    setSearchParams(`tab=${tabs[newValue]}`);
  };

  return (
    <Box my={8}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} variant="scrollable">
          <Tab label="Order Details" />
          <Tab label="Facility" />
          <Tab label="Radiology Results" />
          <Tab label="Activity Log" />
          <Tab
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <span>Support</span>
                {order.discussion.length > 0 && (
                  <Chip
                    label={order.discussion.length}
                    color="primary"
                    size="small"
                    sx={{ opacity: "75%" }}
                  />
                )}
              </Stack>
            }
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <OrderDetailsTab order={order} setBanner={setBanner} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <FacilityTab order={order} onFacilitySelect={setBanner} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ResultsTab reports={order.reports} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <ActivityLogTab />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <SupportTab discussion={order?.discussion} setBanner={setBanner} />
      </TabPanel>
    </Box>
  );
}
