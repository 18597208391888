import { Stack, Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";

export default function SearchField(props) {
  const { label, onSubmit, parentValue } = props;

  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => setValue("search", parentValue), [parentValue, setValue]);

  return (
    <Stack
      component="form"
      direction="row"
      onSubmit={handleSubmit(onSubmit)}
      justifyContent="flex-end"
      sx={{ paddingY: 2 }}
    >
      <Controller
        name="search"
        control={control}
        defaultValue={parentValue}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              type="search"
              margin="normal"
              fullWidth
              label={label}
              sx={{
                "& fieldset": { borderRadius: "4px 0px 0px 4px" },
                marginY: 0,
              }}
              helperText={!!fieldState.error && fieldState.error.message}
              error={!!fieldState.error}
            />
          );
        }}
      />
      <Button
        type="submit"
        variant="contained"
        startIcon={<SearchIcon />}
        disableElevation
        sx={{ px: 6, borderRadius: "0px 4px 4px 0px", height: "56px" }}
      >
        Search
      </Button>
    </Stack>
  );
}
