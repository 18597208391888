import React from "react";
import { Box, Paper, Typography, Stack } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import parsePhoneNumber from "libphonenumber-js";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import maptilerProvider from "../../utils/maptilerProvider";
import FacilityExplorer from "pages/FacilityExplorer";
import useOrdersAPI from "api/OrdersAPI";

export default function FacilityTab(props) {
  const { order, onFacilitySelect } = props;
  const { set_order_facility, send_fax } = useOrdersAPI();

  const phone = parsePhoneNumber(order.facility?.phone || "");
  const coordinates = [
    parseFloat(order.facility?.lat),
    parseFloat(order.facility?.lon),
  ];

  const handleFacilitySelect = async (facilityId) => {
    let results = {
      severity: "success",
      message: "Successfully selected facility and submitted order",
    };

    try {
      await set_order_facility(order.id, facilityId);

      /*
       * Catch potential errors from failed fax. User should see the same
       * message whether the call succeeds or fails. Our ops teams will handle
       * when fails.
       */
      await send_fax(order.id).catch(() => {});
    } catch (err) {
      results = {
        severity: "error",
        message:
          "There was error selecting this facility. Please try again later",
      };
    }

    onFacilitySelect(results);
  };

  const ShowSelectedFacility = () => (
    <Stack direction="row" spacing={4} alignItems="center">
      <Box mt={2}>
        <Typography variant="body1">{order.facility?.name}</Typography>
        <Box mt={1}>
          <Typography variant="body2">
            {order.facility?.street_1} {order.facility?.street_2}
          </Typography>
          <Typography variant="body2">
            {order.facility?.city}, {order.facility?.state}{" "}
            {order.facility?.zip}
          </Typography>
        </Box>
        <Box
          mt={1}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <PhoneIcon sx={{ marginRight: "8px" }} />
          <a href={phone?.getURI()}>{phone?.formatNational()}</a>
        </Box>
      </Box>
      <Map
        provider={maptilerProvider}
        height={600}
        width={600}
        defaultCenter={coordinates}
        defaultZoom={16}
      >
        <ZoomControl />
        <Marker width={50} anchor={coordinates} />
      </Map>
    </Stack>
  );
  return (
    <Paper variant="outlined" sx={{ marginTop: 4, textAlign: "left" }}>
      {order.facility ? (
        <Box sx={{ p: 4 }}>
          <ShowSelectedFacility />
        </Box>
      ) : (
        <FacilityExplorer
          allowSkip={false}
          showStatusStepper={false}
          onFacilitySelect={handleFacilitySelect}
        />
      )}
    </Paper>
  );
}
