// @ts-nocheck
import React, { useEffect, useReducer } from "react";
import * as Sentry from "@sentry/react";
import Uploader from "rc-upload";
import useOrdersAPI from "../../api/OrdersAPI";
import { useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import StatusStepper from "../../components/SubmitOrder/Stepper";
import Confirmation from "../../components/SubmitOrder/Confirmation";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

let initialState = {
  loading: true,
  facility: null,
  uploadedFiles: [],
  uploadingInProgress: false,
  urgency: "Routine",
  isSelfPay: false,

  /* Triggers confirmation component */
  reviewingOrder: false,
  alertMessage: null,
  error: null,
  uploaderError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING DONE":
      return { ...state, facility: action.facility, loading: false };
    case "UPDATE USER INPUT":
      return { ...state, [action.field]: action.value };
    case "ADD UPLOAD FILE":
      state.uploadedFiles.push(action.file);
      return {
        ...state,
        uploadedFiles: state.uploadedFiles,
        uploaderError: null,
      };
    case "DELETE UPLOAD FILE":
      let idx = state.uploadedFiles.indexOf(action.file);
      if (idx !== -1) {
        state.uploadedFiles.splice(idx, 1);
      }
      return { ...state, uploadedFiles: state.uploadedFiles };
    case "SET ALERT":
      return { ...state, alertMessage: action.message };
    case "GO TO CONFIRMATION":
      if (state.uploadedFiles.length === 0) {
        return {
          ...state,
          uploaderError:
            "Please upload the imaging order file to submit a new order",
        };
      }
      return { ...state, reviewingOrder: true, error: null };
    case "BACK TO UPLOAD":
      return { ...state, reviewingOrder: false };
    case "UPDATE FILE LOADING":
      return { ...state, uploadingInProgress: true };
    case "CREATING ORDER ERROR":
      return {
        ...state,
        error: action.error,
        uploadingInProgress: false,
        reviewingOrder: false,
      };
    case "UPLOADER ERROR":
      return {
        ...state,
        uploaderError: action.uploaderError,
        uploadingInProgress: false,
      };
    default:
      return state;
  }
};

export default function UploadFiles() {
  let [state, dispatch] = useReducer(reducer, initialState, (initialState) => {
    initialState.uploadedFiles = [];
    return initialState;
  });
  let { facilityId } = useParams();
  let navigate = useNavigate();
  let {
    get_facility,
    create_order,
    set_order_facility,
    start_file_attachment,
    upload_attachment,
    send_fax,
  } = useOrdersAPI();
  let { control, getValues, handleSubmit } = useForm();

  useEffect(() => {
    if (facilityId === "skipped") {
      // Handle when facility selection is skipped
      dispatch({ type: "LOADING DONE", facility: undefined });
    } else if (facilityId) {
      get_facility(facilityId, (facility) =>
        dispatch({ type: "LOADING DONE", facility })
      );
    }
  }, [facilityId, get_facility]);

  let handleBackClick = () => {
    if (state.reviewingOrder) {
      dispatch({ type: "BACK TO UPLOAD" });
    } else {
      navigate("/");
    }
  };

  let PriorityRadioGroup = () => {
    return (
      <FormControl>
        <Typography variant="h5" textAlign="left" mb={1}>
          Imaging Order Priority
        </Typography>
        <RadioGroup
          row
          name="urgency"
          onChange={(e) =>
            dispatch({
              type: "UPDATE USER INPUT",
              field: e.target.name,
              value: e.target.value,
            })
          }
          value={state.urgency}
        >
          <FormControlLabel
            value="Routine"
            control={<Radio />}
            label="Routine"
          />
          <FormControlLabel value="Stat" control={<Radio />} label="Stat" />
        </RadioGroup>
      </FormControl>
    );
  };

  let SelfPayRadioGroup = () => {
    return (
      <FormControl>
        <Typography variant="h5" textAlign="left" mb={1}>
          Is the patient self-pay?
        </Typography>
        <RadioGroup
          row
          name="isSelfPay"
          onChange={(e) =>
            dispatch({
              type: "UPDATE USER INPUT",
              field: e.target.name,
              value: e.target.value,
            })
          }
          value={state.isSelfPay}
        >
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    );
  };

  let PatientNameForm = () => {
    return (
      <Box component="form" mb={4}>
        <Typography variant="h5" textAlign="left" mb={4}>
          Patient's First and Last Name
        </Typography>
        <Stack direction="row" spacing={4}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="First Name"
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && "Required"}
                fullWidth
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Last Name"
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && "Required"}
                fullWidth
              />
            )}
          />
        </Stack>
      </Box>
    );
  };

  let handleNextClick = async () => {
    if (state.reviewingOrder) {
      dispatch({ type: "UPDATE FILE LOADING" });
      let [firstName, lastName] = getValues(["firstName", "lastName"]);

      let order = {
        patient: {
          external_id: uuidv4(),
          name: `${firstName} ${lastName}`,
          dob: "1970-01-01",
        },
        requester: {
          NPI: "00000000",
        },
        urgency: state.urgency,
        is_self_pay: state.isSelfPay,
      };

      /*
       * Create order and make a request to start and upload each file as an
       * order attachment.
       */
      try {
        let newOrder = await create_order(order);

        if (state.facility) {
          await set_order_facility(newOrder.id, state.facility.id);
        }

        let uploadPromises = state.uploadedFiles.map(
          (file) =>
            new Promise((resolve) => {
              if (!file) {
                return resolve();
              }
              start_file_attachment(newOrder.id, ({ id: attachmentId }) => {
                upload_attachment(newOrder.id, attachmentId, file, () =>
                  resolve()
                );
              });
            })
        );

        await Promise.all(uploadPromises);

        if (state.facility) {
          await send_fax(newOrder.id).catch((error) => {
            if (error.response.status !== 400) {
              Sentry.captureException(error);
            }
          });
        }

        navigate(`/orders/${newOrder.id}?tab=details`);
      } catch {
        let error =
          "We've encountered an error. Please try again later, sorry for the inconvenience.";
        dispatch({ type: "CREATING ORDER ERROR", error });
      }
    } else {
      handleSubmit(() => dispatch({ type: "GO TO CONFIRMATION" }))();
    }
  };

  let uploadValidation = (file) => {
    if (file.size >= 1024 * 1024 * 100) {
      dispatch({
        type: "SET ALERT",
        message: "File exceeds 100MB size limit",
      });
      return false;
    }
  };

  let uploadProps = {
    accept: ".pdf",
    openFileDialogOnClick: true,
    type: "drag",
    multiple: true,

    // Fix first error request
    customRequest: () => {},

    beforeUpload: uploadValidation,
    action: (file) => {
      dispatch({ type: "ADD UPLOAD FILE", file });
      dispatch({ type: "SET ALERT", message: "" });
    },

    style: {
      position: "relative",
      display: "inline-block",
      background: grey[100],
      border: state.uploaderError
        ? `2px dashed ${red[400]}`
        : `2px dashed ${grey[400]}`,
      borderRadius: 8,
    },

    onError: (err) => {
      console.log("onError", err);
    },
  };

  let requiredInfo = [
    <span>A signed imaging order with study details</span>,
    <span>Clinical or progress notes</span>,
    <span>
      Patient's insurance information (optional for <b>self-pay</b> patients)
    </span>,
  ];

  let renderUploadFiles = () => (
    <Stack spacing={4} sx={{ px: 10, py: 4 }}>
      <PatientNameForm />
      <Divider />

      <Typography variant="h5" textAlign="left">
        Patient's Referral Documents
      </Typography>
      <Uploader {...uploadProps}>
        <Stack spacing={2} sx={{ px: 8, py: 4 }} alignItems="flex-start">
          <Typography variant="h6" color="text.secondary">
            <b>Verify the following information is included:</b>
          </Typography>
          <ul style={{ paddingLeft: "20px" }}>
            {requiredInfo.map((info, index) => (
              <li key={index}>
                <Typography variant="body1" textAlign="left" pl={2}>
                  {info}
                </Typography>
              </li>
            ))}
          </ul>

          <Button variant="contained" sx={{ alignSelf: "center" }}>
            Upload File(s)
          </Button>
          {state.uploadedFiles.map((file) => (
            <Button
              key={file.uid}
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: "DELETE UPLOAD FILE",
                  file,
                });
              }}
              endIcon={<HighlightOffIcon />}
              sx={{ textTransform: "none", px: 4, alignSelf: "center" }}
            >
              <b>{`FILE: ${file.name}`}</b>
            </Button>
          ))}
          {state.alertMessage && (
            <Alert sx={{ alignSelf: "center" }} severity="error">
              {state.alertMessage}
            </Alert>
          )}
        </Stack>
        {state.uploaderError && (
          <Typography variant="body1" py={1} color={red[400]}>
            {state.uploaderError}
          </Typography>
        )}
      </Uploader>
      <SelfPayRadioGroup />

      <Divider />
      <PriorityRadioGroup />

      {renderBackNextButtons()}
    </Stack>
  );

  let renderBackNextButtons = () => (
    <Stack direction="row" justifyContent="space-between" pt={2}>
      <Button
        color="primary"
        onClick={handleBackClick}
        sx={{ pr: 6 }}
        startIcon={<ArrowBackIosNewIcon fontSize="inherit" />}
      >
        Back
      </Button>
      <Button variant="contained" onClick={handleNextClick} sx={{ px: 6 }}>
        {state.reviewingOrder ? "Submit Order" : "Review Order"}
      </Button>
    </Stack>
  );

  let renderConfirmation = () => (
    <Confirmation
      state={state}
      patientName={`${getValues("firstName")} ${getValues("lastName")}`}
      handleBack={handleBackClick}
      handleSubmit={handleNextClick}
    />
  );

  return (
    !state.loading && (
      <>
        {state.error && (
          <Alert
            severity="error"
            alignItems="center"
            sx={{
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              textAlign: "center",
            }}
          >
            {state.error}
          </Alert>
        )}
        <Container maxWidth="md" sx={{ paddingTop: "64px" }}>
          <Stack
            spacing={4}
            sx={{ padding: "16px", marginBottom: "32px", marginTop: "32px" }}
          >
            <StatusStepper status={state.reviewingOrder ? 2 : 1} />
            <Typography variant="h4">Review and Submit Order</Typography>
            <Paper variant="outlined">
              <Box p={4} minWidth="788px">
                {state.reviewingOrder
                  ? renderConfirmation()
                  : renderUploadFiles()}
              </Box>
            </Paper>
          </Stack>
        </Container>
      </>
    )
  );
}
