import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";

export default function Link(props) {
  const { to, label } = props;
  return (
    <RouterLink to={to}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </RouterLink>
  );
}
