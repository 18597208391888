import { startCase } from "lodash";
import parsePhoneNumber from "libphonenumber-js";
import { Stack, Link, Typography } from "@mui/material";

function formatImagingOrderChanges(changes) {
  const showFields = ["status", "status_message", "facility"];
  let changesList = [];

  Object.entries(changes).forEach(([key, value]) => {
    if (showFields.includes(key)) {
      changesList.push(
        <Typography key={key} variant="body2" lineHeight={1.1}>
          <Typography component="span" variant="overline" lineHeight={1.1}>
            {startCase(key).toLowerCase()}
          </Typography>
          : {formatOrderValue(key, value[1])}
        </Typography>
      );
    }
  });

  return <Stack spacing={1}>{changesList}</Stack>;
}

function translateOrderStatus(status) {
  const statusMap = {
    SUB: "Draft",
    UR: "Under Review",
    FS: "Fax Sent",
    IP: "Fulfillment In Progress",
    RR: "Report Ready",
    AR: "Action Required",
    CA: "Cancelled",
    "IA:PU": "Inactive - Patient Unreachable",
    "IA:NS": "Inactive - Patient No-Show",
    "IP:SCH": "Fulfillment In Progress - Scheduled",
    "RR:P": "Report Ready - Partial Results",
    "RR:DTR": "Report Ready - Sent Directly To Referrer",
    "CA:PD": "Cancelled - Patient Declined",
  };
  return statusMap[status];
}

export function formatOrderValue(field, value) {
  switch (field) {
    case "status":
      return translateOrderStatus(value);
    case "facility":
      return value.split("<>")[0];
    default:
      return value;
  }
}

function formatFaxChanges(changes) {
  const showFields = ["status", "contact", "fax_number", "document_type"];
  let changesList = [];

  Object.entries(changes).forEach(([key, value]) => {
    if (showFields.includes(key)) {
      if (!formatFaxValue(key, value[1])) {
        return;
      }

      changesList.push(
        <Typography key={key} variant="body2" lineHeight={1.1}>
          <Typography component="span" variant="overline" lineHeight={1.1}>
            {startCase(key).toLowerCase()}
          </Typography>
          : {formatFaxValue(key, value[1])}
        </Typography>
      );
    }
  });

  return <Stack spacing={1}>{changesList}</Stack>;
}

function translateFaxStatus(status) {
  const statusMap = {
    F: "Failed",
    S: "Success",
    T: "Transmitting",
    P: "Processing",
    SCH: "Scheduled",
  };
  return statusMap[status];
}

function translateDocumentType(documentType) {
  const typeMap = {
    RR: "Radiology Report",
    IO: "Imaging Order",
  };
  return typeMap[documentType];
}

export function formatFaxValue(field, value) {
  switch (field) {
    case "status":
      return translateFaxStatus(value);
    case "contact":
      return value.split("<>")[0];
    case "fax_number":
      const phone = parsePhoneNumber(value);
      return phone.formatNational();
    case "document_type":
      return translateDocumentType(value);
    default:
      return value;
  }
}

export function buildDetail(entry) {
  switch (entry.action) {
    case "update":
      if (entry.object_repr.includes("Imaging Order")) {
        return formatImagingOrderChanges(entry.changes);
      } else if (entry.object_repr.includes("Fax")) {
        return formatFaxChanges(entry.changes);
      }
      return "Updated";
    case "create":
      if (entry.object_repr.includes("Fax")) {
        return formatFaxChanges(entry.changes);
      }
      return "";
    default:
      return "";
  }
}

export function buildObject(entry) {
  if (entry.object_repr.includes("Imaging Order")) {
    return (
      <Link href={`/orders/${entry.object_pk}?tab=details`}>
        {entry.object_repr}
      </Link>
    );
  }
  return <span>{entry.object_repr}</span>;
}

export function buildActor(entry) {
  if (entry?.actor?.institution === "imagingpanda") {
    return "Imaging Panda";
  }
  return entry?.actor?.username || "system";
}

export function buildActivity(entry) {
  return (
    <span>
      {buildActor(entry)} {`${entry.action}d`} {buildObject(entry)}
    </span>
  );
}
