import PageContainer from "components/PageContainer";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const SectionHeader = styled(Typography)({
  textAlign: "left",
  fontWeight: "bold",
  textDecoration: "underline",
});

const ListItem = styled(Typography)({
  textAlign: "left",
  paddingLeft: 16,
});

export default function TermsOfService() {
  return (
    <PageContainer header="Services Agreement">
      <Typography fontWeight="bold">*** IMPORTANT ***</Typography>
      <Typography fontWeight="bold">
        PLEASE READ CAREFULLY BEFORE YOU USE THE IMAGING PANDA SERVICES
      </Typography>
      <Typography textAlign="left" fontWeight="bold">
        THIS DOCUMENT (THE &ldquo;AGREEMENT&rdquo;) IS A LEGAL AGREEMENT BETWEEN
        IMAGING PANDA, INC. (&ldquo;IMAGING PANDA&rdquo;) AND YOU
        (&ldquo;CUSTOMER&rdquo;). THE &nbsp;SERVICES PROVIDED BY IMAGING PANDA
        (THE &ldquo;IMAGING PANDA SERVICES&rdquo;) ARE THE EXCLUSIVE PROPERTY OF
        IMAGING PANDA OR ITS LICENSORS AND ARE PROTECTED BY UNITED STATES AND
        INTERNATIONAL INTELLECTUAL PROPERTY LAWS. THE IMAGING PANDA SERVICES ARE
        COPYRIGHTED AND LICENSED (NOT SOLD). IMAGING PANDA IS ONLY WILLING TO
        LICENSE THE IMAGING PANDA SERVICES SUBJECT TO THE TERMS AND CONDITIONS
        OF THIS AGREEMENT, AND ANY USE OF THE IMAGING PANDA SERVICES OUTSIDE THE
        SCOPE OF SUCH TERMS AND CONDITIONS IS PROHIBITED.
      </Typography>
      <br />
      <Typography textAlign="left" fontWeight="bold">
        BY USING ANY SERVICES PROVIDED BY BUSINESS ASSOCIATE (THE "SERVICES"),
        YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT AND
        AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. IF YOU ARE NOT WILLING TO
        BE BOUND BY THE TERMS OF THIS AGREEMENT, DO NOT USE THE SERVICES.
      </Typography>
      <br />
      <Typography textAlign="left" fontWeight="bold">
        IF YOU ARE USING THE IMAGING PANDA SERVICES IN YOUR CAPACITY AS AN
        EMPLOYEE OR AGENT OF A COMPANY OR ORGANIZATION, THEN ANY REFERENCES TO
        THE &ldquo;CUSTOMER&rdquo; IN THIS AGREEMENT SHALL REFER TO SUCH ENTITY
        AND NOT TO YOU IN YOUR PERSONAL CAPACITY. YOU WARRANT THAT YOU ARE
        AUTHORIZED TO LEGALLY BIND THE CUSTOMER. IF YOU ARE NOT SO AUTHORIZED,
        THEN NEITHER YOU NOR THE CUSTOMER MAY USE THE IMAGING PANDA SERVICES IN
        ANY MANNER WHATSOEVER.
      </Typography>
      <br />
      <br />
      <ol>
        <li>
          <SectionHeader>PARTNERSHIP ACTIVITIES</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Imaging Panda Obligations. &nbsp;During the Term (as defined
                below), Imaging Panda shall be responsible for fulfilling its
                obligations set forth in Exhibit A attached hereto (the
                &ldquo;Imaging Panda Obligations&rdquo;). &nbsp;Subject to
                Customer&rsquo;s compliance with the terms and conditions of
                this Agreement, Imaging Panda will deliver the Imaging Panda
                Platform (as defined in Exhibit A) to Customer in a mutually
                agreed upon format and cadence, defined in further detail in
                Exhibit A.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Customer Obligations and License Rights. &nbsp;During the Term,
                Customer shall be responsible for fulfilling its obligations set
                forth in Exhibit A (the &ldquo;Customer Obligations&rdquo;, and
                together with the Imaging Panda Obligations, the
                &ldquo;Partnership Activities&rdquo;). &nbsp;Subject to the
                terms and conditions of this Agreement, Imaging Panda hereby
                grants Customer a non-exclusive, non-sublicensable,
                non-transferable license and right, during the Term, to use the
                Imaging Panda Platform as part of Customer&rsquo;s services to
                deliver and/or coordinate care (the &ldquo;Customer
                Platform&rdquo;), solely for the permitted uses set forth in
                Exhibit A (the &ldquo;Permitted Use&rdquo;). &nbsp;Except as
                expressly permitted herein, the Imaging Panda Platform must
                never be provided to third parties outside the Customer
                organization, and Customer must undertake industry-standard
                precautions to protect the Imaging Panda Platform from being
                collected or scraped (including incorporating restrictions
                against scraping, collection and harvesting of Imaging Panda
                Platform in its terms of service). &nbsp;&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                License Restrictions and Requirements. &nbsp;Customer shall not,
                directly or indirectly: (i) market or distribute the Imaging
                Panda Platform or any portion thereof on a &ldquo;stand
                alone&rdquo; basis not explicitly declared in the Permitted Uses
                in Exhibit A, (ii) to the extent any software is included in the
                Imaging Panda Services, reverse engineer, decompile,
                disassemble, modify, create derivative works of or otherwise
                create, attempt to create or derive, or permit or assist any
                third party to create or derive, the source code underlying such
                software, or (iii) otherwise use or exploit the Imaging Panda
                Services in violation of applicable law or outside of the
                express scope of the license in Section 1.2. &nbsp;Customer may
                not utilize the Imaging Panda Platform in any manner that is
                directly competitive to the Imaging Panda Services, including
                but not limited to, directly or indirectly (i) soliciting or
                knowingly inducing, or attempting to induce, any imaging
                providers contained in the Imaging Panda Platform (&ldquo;Listed
                Imaging Providers&rdquo;) to terminate their relationship with,
                or otherwise cease their relationship with Imaging Panda or (ii)
                attempt to directly list such Listed Imaging Providers on the
                Customer Platform other than for the Permitted Use in connection
                with the Customer Obligations. &nbsp;Customer acknowledges and
                agrees that due to the unique nature of the Imaging Panda
                Services, there can be no adequate remedy at law for any breach
                of this Section, that any such breach may allow Customer or
                third parties to unfairly compete with Imaging Panda, and
                therefore, that upon any such breach or any threat thereof,
                Imaging Panda will be entitled to appropriate equitable relief
                (without the posting of any bond) in addition to whatever
                remedies it might have at law and to be indemnified by Customer
                from any loss or harm, including, without limitation, lost
                profits and reasonable attorney&rsquo;s fees, in connection with
                any breach or enforcement of Customer&rsquo;s obligations
                hereunder or the unauthorized use or release of the Imaging
                Panda Platform.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>PAYMENT AND EXPENSES.</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Payment. &nbsp;All payments shall be made in accordance with the
                separate order form entered into by the parties. &nbsp;Unless
                otherwise agreed to by the Parties in writing, each Party shall
                be responsible for its own costs and expenses in connection with
                its respective Partnership Activities. &nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Taxes. &nbsp;Each Party shall be responsible for and pay and all
                applicable federal, state, local and foreign taxes, duties,
                tariffs, levies, withholdings and similar assessments resulting
                from its own activities under this Agreement.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>TRADEMARK RIGHTS</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Use or Marks. &nbsp;During the Term, Imaging Panda hereby grants
                to Customer a non-exclusive, royalty-free, non-transferrable
                license to use Imaging Panda&rsquo;s trademarks, logos, services
                marks, and trade names (collectively, the &ldquo;Imaging Panda
                Marks&rdquo;) in order to provide attribution for
                Customer&rsquo;s use of the Imaging Panda Platform as set forth
                in Exhibit A. &nbsp;All use of the Imaging Panda Marks will be
                subject to Imaging Panda&rsquo;s trademark usage guidelines,
                which may be provided by Imaging Panda from time to time, and
                all goodwill created thereby will inure solely for the benefit
                of Imaging Panda. &nbsp;During the Term, Customer hereby grants
                to Imaging Panda a non-exclusive, royalty-free,
                non-transferrable license to use Customer&rsquo;s trademarks,
                logos, service marks, and trade names (collectively, the
                &ldquo;Customer Marks&rdquo;) for marketing purposes (including
                identification on Imaging Panda&rsquo;s website and through
                press releases) and to identify Customer in case studies.
                &nbsp;Such usage of the Customer Marks will require
                Customer&rsquo;s prior approval, not to be unreasonably
                withheld. &nbsp;All use of the Customer Marks will be subject to
                Customer&rsquo;s trademark usage guidelines, which may be
                provided by Customer from time to time, and all goodwill created
                thereby will inure solely for the benefit of Customer.
                &nbsp;Neither Party will dispute, impair or impugn the validity
                or ownership of the other Party&rsquo;s trademarks, logos,
                services marks and trade names.
              </ListItem>
            </li>
            <li>
              <ListItem>
                No Other Rights. &nbsp;Except as expressly provided for herein
                (including Section 1.2), there are no other licenses or other
                rights granted by either Party with respect to the intellectual
                property or other proprietary rights of such Party, and each
                Party shall retain ownership of all of its own intellectual
                property, work product, and other materials developed or
                otherwise used by it in connection with this Agreement. &nbsp;
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>CONFIDENTIALITY</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Definition. &nbsp;&ldquo;Confidential Information&rdquo; means:
                (i) information that is disclosed in written form and that is
                clearly labeled as proprietary, confidential or with words of
                similar meaning; (ii) information that is disclosed orally or
                visually and that is identified as proprietary or confidential
                at the time of its disclosure and is summarized in a writing
                sent by the disclosing Party to the other Party within thirty
                (30) days of such disclosure; and (iii) any information that,
                due to its nature or the circumstances of disclosure, would
                reasonably be deemed confidential. &nbsp;For clarity, the
                Imaging Panda Platform will be deemed the Confidential
                Information of Imaging Panda. &nbsp;The terms and conditions of
                this Agreement will be deemed the Confidential Information of
                both Parties.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Exclusions. &nbsp;The obligations and restrictions in Section 4
                will not apply to any information that: (i) is or becomes
                generally known to the public through no fault of or breach of
                this Agreement by the receiving Party; (ii) is rightfully known
                by the receiving Party prior to the disclosure of such
                information from the disclosing Party; (iii) is independently
                developed by the receiving Party without use of the disclosing
                Party&rsquo;s Confidential Information; or (iv) the receiving
                Party rightfully obtains from a third party who had the right to
                disclose such information without breach of any confidentiality
                obligation to the disclosing Party.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Use and Nondisclosure. &nbsp;During and after the Term, each
                Party (the &ldquo;Receiving Party&rdquo;) will not use the
                Confidential Information of the other Party (the
                &ldquo;Disclosing Party&rdquo;) for any purpose other than for
                the performance and enforcement of this Agreement and will not
                disclose the other Party&rsquo;s Confidential Information to any
                person or entity other than to those of its employees and
                contractors who need to know such Confidential Information for a
                Party&rsquo;s performance and enforcement of this Agreement;
                provided that each such employee and contractor is bound by a
                written agreement that contains use and nondisclosure
                restrictions at least as protective of the Confidential
                Information as those set forth in this Agreement. &nbsp;Each
                Party will use the same efforts to protect the confidentiality
                of the other Party&rsquo;s Confidential Information that it
                ordinarily uses to protect the confidentiality of its own
                confidential information of like importance, but in no event
                less than reasonable efforts.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Permitted Disclosure. &nbsp;The foregoing provisions of this
                Section 4 will not restrict either Party from disclosing the
                other Party&rsquo;s Confidential Information or the terms and
                conditions of this Agreement: (i) pursuant to the order or
                requirement of a court, administrative agency, or other
                governmental body; provided that the Party required to make such
                a disclosure gives reasonable notice to the other Party to
                enable it to contest such order or requirement; or (ii) on a
                confidential basis to its legal or professional financial
                advisors.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Equitable Relief. &nbsp;The Receiving Party acknowledges and
                agrees that due to the unique nature of the Disclosing
                Party&rsquo;s Confidential Information, there can be no adequate
                remedy at law for any breach of its obligations hereunder, which
                breach may result in irreparable harm to the Disclosing Party,
                and therefore, that upon any such breach or any threat thereof,
                the Disclosing Party shall be entitled to appropriate relief in
                addition to whatever remedies it might have at law.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>
            REPRESENTATIONS AND WARRANTIES; DISCLAIMER&nbsp;
          </SectionHeader>
          <ol>
            <li>
              <ListItem>
                Mutual Representations and Warranties. &nbsp;Each Party
                represents and warrants to the other Party that: (a) it is an
                entity duly organized, validly existing, and in good standing
                under the laws of its place of incorporation and has full
                corporate power and authority to own its properties and to
                conduct its businesses with which it is now engaged; (b) it has
                full corporate power and authority to execute and deliver this
                Agreement, and to perform all of its obligations hereunder, and
                no consent or approval of any other person or governmental
                authority is required therefor; (c) it will comply with all
                applicable laws and regulations with respect to its activities
                hereunder; and (d) it is not under any pre-existing obligation
                in conflict or in any way inconsistent with the provisions of
                this Agreement.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Regulatory Matters. &nbsp;If and to the extent, and so long as,
                required by applicable privacy and security laws, each Party
                hereby assures the other Party that it will appropriately
                safeguard the patient data made available to or obtained by such
                Party. &nbsp;In implementation of such assurance, and without
                limiting the obligations of the Parties otherwise set forth in
                this Agreement or imposed by applicable law, (i) each Party
                hereby agrees to comply with applicable requirements of law
                relating to patient data and with respect to any task or other
                activity the Parties perform, to the extent that any Party would
                be required to comply with such requirements, and (ii) the
                Parties shall enter into a Business Associate Agreement in a
                mutually agreeable form. &nbsp;In performing their respective
                obligations herein, the Parties intend to act in compliance with
                all applicable laws and regulations that relate to the
                performance of this Agreement. &nbsp;Accordingly, no part of any
                consideration paid hereunder is intended to be a prohibited
                payment for the recommending or arranging for the referral of
                business or the ordering of items or services; nor are the
                payments intended to induce illegal referrals of business.
                &nbsp;Customer represents and warrants that none of its owners,
                officers, or any person providing any services on its behalf in
                connection with the Agreement is currently excluded from
                participation in any federal health care program. &nbsp;Customer
                shall promptly notify Imaging Panda if Customer becomes aware
                that Customer, any of its owners, officers, directors, or
                employees is under investigation for legal noncompliance by any
                government agency or law enforcement authority.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Additional Representations. &nbsp;Customer represents and
                warrants that it is responsible for ensuring that radiological
                imaging results are conveyed to the patient and/or his or her
                ordering physician within appropriate timeframes. &nbsp;Customer
                represents and warrants that it will not recommend, endorse, or
                select a licensed health care provider for any patient.
                &nbsp;Customer shall inform patients at the time of purchase
                that, if upon patient&rsquo;s consultation with his or her
                selected imaging provider such provider determines that the
                purchased services are not suitable or appropriate for the
                patient, patient shall be entitled to a refund for such
                services. &nbsp;Customer represents and warrants that it will
                not take any action to influence or inhibit any licensed medical
                professional&rsquo;s exercise of independent medical
                judgment.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Disclaimers. &nbsp;EXCEPT AS EXPRESSLY PROVIDED IN THIS
                AGREEMENT, EACH PARTY DISCLAIMS ALL REPRESENTATIONS OR
                WARRANTIES OF ANY KIND WHATSOEVER (INCLUDING WITH RESPECT TO ITS
                OWN PRODUCTS AND SERVICES), EXPRESS OR IMPLIED, INCLUDING ANY
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING FROM
                COURSE OF DEALING OR USAGE OF TRADE. &nbsp;
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>LIMITATION OF LIABILITY</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Limitation of Liability. &nbsp;EXCEPT FOR LIABILITY RESULTING
                FROM EITHER PARTY&rsquo;S INDEMNIFICATION OBLIGATIONS OR BREACH
                OF SECTION 4 (CONFIDENTIALITY) OR CUSTOMER&rsquo;S BREACH OF
                SECTION 1.3, IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE
                OTHER PARTY FOR (A) ANY SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY
                OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF
                USE, PROFITS, GOODWILL, REVENUE OR DATA, OR BUSINESS
                INTERRUPTION OR THE COST OF PROCURING SUBSTITUTE SERVICES OR
                OTHER ECONOMIC LOSS, OR DAMAGES; OR (B) ANY AGGREGATE LIABILITY
                IN EXCESS OF THE AGGREGATE FEES PAID (PLUS AMOUNTS PAYABLE) BY
                CUSTOMER HEREUNDER IN THE TWELVE (12) MONTHS PRECEDING THE DATE
                THE CLAIM AROSE.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Acknowledgement. &nbsp;THE FOREGOING LIMITATIONS OF LIABILITY
                SHALL APPLY (I) REGARDLESS OF THE FORM OR THEORY ON WHICH A
                CLAIM OR ACTION IS BASED, WHETHER IN CONTRACT OR IN TORT
                (INCLUDING NEGLIGENCE OR RELIANCE), PRODUCT LIABILITY OR
                OTHERWISE; (II) EVEN IF SUCH PARTY KNOWS OR HAS BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES; AND (III) NOTWITHSTANDING ANY
                FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY PROVIDED FOR
                IN THIS AGREEMENT.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>INDEMNIFICATION</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Mutual Indemnification. &nbsp;Each party shall defend, indemnify
                and hold harmless the other party from any and all damages,
                liability, losses, settlement, attorneys&rsquo; fees and
                expenses payable to a third party as a result of a third party
                claim, action or suit resulting from (i) any gross negligence or
                willful misconduct by the indemnifying party or (ii)
                infringement by the indemnifying party&rsquo;s products and/or
                services of any third party intellectual property rights.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Customer Indemnification Obligations. &nbsp;Customer shall
                defend, indemnify and hold harmless Imaging Panda, its owners,
                officers, directors, employees, and agents, from and against any
                and all damages, liability, losses, settlements,
                attorneys&rsquo; fees and expenses payable to a third party as a
                result of a third party claim, action, or suit related to or
                arising from Customer&rsquo;s failure to convey or timely convey
                radiological images or results to imaging center patients or
                such patients&rsquo; physicians.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Indemnification Procedure. &nbsp;The indemnified party shall (i)
                give written notice to the indemnifying party promptly after
                learning of such claim, (ii) tender the defense and settlement
                of the claim to the indemnifying party (provided that the
                indemnifying party may not settle any claim without the
                indemnified party&rsquo;s prior written consent, not to be
                unreasonable withheld), and (iii) provide the indemnifying party
                with reasonable assistance, at the indemnifying party&rsquo;s
                reasonable expense, in connection with the defense and
                settlement of such claim.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>TERM AND TERMINATION</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Term. &nbsp;This Agreement shall commence on the date that
                Customer accepts the Agreement (the &ldquo;Effective
                Date&rdquo;) and shall continue for a period of ONE YEAR, unless
                terminated as provided for herein (the &ldquo;Term&rdquo;). Upon
                the end of the Term, the Agreement shall automatically renew for
                additional periods of one (1) year unless otherwise terminated
                by either party in writing (each a &ldquo;Renewal Term&rdquo;)
                prior to the end of the preceding Term.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Termination for Cause. &nbsp;Either Party may terminate this
                Agreement upon written notice if the other Party breaches any
                material term of this Agreement and fails to cure such breach
                within thirty (30) days following written notice thereof from
                the non-breaching Party.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Termination for Convenience. &nbsp;Either Party may terminate
                this Agreement upon sixty (60) days&rsquo; prior written notice
                to the other Party.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Effect of Termination. &nbsp;Upon any expiration or termination
                of this Agreement: (i) all rights granted hereunder and all
                obligation of Imaging Panda to provide the Imaging Panda
                Platform shall immediately terminate; (ii) each Party will
                return and make no further use of any other Confidential
                Information of the other Party; and (iii) Customer agrees to pay
                within 14 days of the termination date all outstanding amounts
                owed to Imaging Panda pursuant to this Agreement. &nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Survival. &nbsp;The rights and obligations of the Parties under
                Sections 1.2, 1.3, 4, 5.4, 6, 7, 8, and 9 will survive any
                expiration or termination of this Agreement.
              </ListItem>
            </li>
          </ol>
        </li>
        <li>
          <SectionHeader>GENERAL</SectionHeader>
          <ol>
            <li>
              <ListItem>
                Assignment. &nbsp;Neither Party may assign or transfer this
                Agreement, in whole or in part, without the other Party&rsquo;s
                written consent; provided that each Party may assign this
                Agreement in connection with a merger, acquisition or sale of
                all or substantially all of its business or assets related to
                this Agreement. &nbsp;Subject to the foregoing restrictions,
                this Agreement will inure to the benefit of the successors and
                permitted assigns of the Parties.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Governing Law. &nbsp;This Agreement is to be construed in
                accordance with and governed by the laws of the State of
                California without giving effect to any choice of law rules.
                &nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Waiver; Severability. &nbsp;The waiver by either Party of any
                default or breach of this Agreement will not constitute a waiver
                of any other or subsequent default or breach. &nbsp;In the event
                any provision of this Agreement is held to be invalid or
                unenforceable, the remaining provisions of this Agreement will
                remain in full force and effect.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Notices. &nbsp;Any notice or other communication given to a
                Party under or in connection with the Agreement shall be in
                writing, addressed to that Party at its principal place of
                business or such other address as that Party may have specified
                to the other Party in writing in accordance with this Section,
                and may be delivered personally, sent by pre-paid first class
                mail or other next working day delivery service or commercial
                courier or via e-mail.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Relationship Between the Parties. &nbsp;Nothing in this
                Agreement will be construed to create a legal partnership, joint
                venture or agency relationship between the Parties.
                &nbsp;Neither Party will have the power to bind the other or to
                incur obligations on the other&rsquo;s behalf without such other
                Party&rsquo;s prior written consent.&nbsp;
              </ListItem>
            </li>
            <li>
              <ListItem>
                Force Majeure. &nbsp;Neither Party will be liable hereunder by
                reason of any failure or delay in the performance of its
                obligations due to causes beyond its reasonable including
                without limitation acts of God, inclement weather conditions,
                epidemic, war, terrorism, governmental action, labor conditions,
                riot, acts of civil or military authorities, fire, floods,
                earthquakes, accidents and denial-of-service attacks (each a
                &ldquo;Force Majeure Event&rdquo;). &nbsp;Upon the occurrence of
                a Force Majeure Event, the non-performing Party will promptly
                notify the other Party and will be excused from any further
                performance of its obligations effected by the Force Majeure
                Event for so long as the event continues and such Party
                continues to use commercially reasonable efforts to resume
                performance.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Headings. &nbsp;Headings and captions are for convenience only
                and are not to be used in the interpretation of this Agreement.
              </ListItem>
            </li>
            <li>
              <ListItem>
                Entire Agreement. &nbsp;This Agreement together with the
                exhibits hereto, entered on or around the Effective Date,
                constitutes the complete and exclusive agreement between the
                Parties concerning its subject matter and supersedes all prior
                or contemporaneous agreements or understandings, written or
                oral, concerning the subject matter of this Agreement.
                &nbsp;This Agreement may not be modified or amended except in a
                writing signed by a duly authorized representative of each
                Party.
              </ListItem>
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <p>&nbsp; &nbsp;&nbsp;</p>
      <Typography fontWeight="bold">EXHIBIT A</Typography>
      <Typography fontWeight="bold">
        IMAGING PANDA PLATFORM; PARTNERSHIP ACTIVITIES&nbsp;
      </Typography>
      <SectionHeader>Imaging Panda Platform</SectionHeader>
      <ul>
        <li>
          <ListItem>
            Contains the following types of information and/or data: Imaging
            Panda Platform includes imaging center physical address and services
            offered at center. &nbsp;
          </ListItem>
        </li>
        <li>
          <ListItem>
            Delivery Format: Web Application. Imaging Panda Platform, as used in
            the Agreement, also includes any and all technology and tools
            provided by Imaging Panda in connection with the delivery of Imaging
            Panda&rsquo;s information and data, including the Web
            Application.&nbsp;
          </ListItem>
        </li>
        <li>
          <ListItem>
            Clinical Directory: Imaging Panda will provide Customer with a
            directory of locations who can perform various clinical
            services.&nbsp;
          </ListItem>
        </li>
        <li>
          <ListItem>
            Permitted Use: To be used by Customer solely for the purposes of
            providing users of the Customer Platform with access to the services
            provided by Imaging Panda
          </ListItem>
        </li>
        <li>
          <ListItem>
            Cadence of data updates: Data will be updated in real-time through
            the Web Application.
          </ListItem>
        </li>
        <li>
          <ListItem>
            Support and Remedy: Imaging Panda will provide Customer with
            commercially reasonable support. &nbsp;In the event that the quality
            and/or latency of matching is below commercially reasonable
            standards, Imaging Panda will collaborate with Customer to improve
            the implementation. &nbsp;The process can include but is not limited
            to: Proposals for new implementation, problem-solving phone calls
            and/or meetings to solve potential problems, and commercially
            reasonable sharing of non-proprietary matching scripts to improve
            implementation.&nbsp;
          </ListItem>
        </li>
      </ul>
      <br />
      <p>
        Both Imaging Panda and Customer agree that all Services provided in
        implementation are based on best information available to Imaging Panda
        at the time.
      </p>
      <SectionHeader>Scheduling Workflow&nbsp;</SectionHeader>
      <ol>
        <li>
          <ListItem>
            Customer uses Web Application to allow users of the Customer
            Platform to access services from Imaging Panda. To the extent
            Customer is sending patient information via email, Customer shall
            encrypt such email and undertake all other appropriate data
            protection measures to ensure compliance with applicable patient
            privacy and data security laws.&nbsp;
          </ListItem>
        </li>
        <li>
          <ListItem>
            Imaging Panda sends the imaging center the doctor&rsquo;s order and
            patient information
          </ListItem>
        </li>
        <li>
          <ListItem>
            Imaging center schedules patient at the selected imaging
            center&nbsp;
          </ListItem>
        </li>
      </ol>
      <SectionHeader>Imaging Panda Obligations</SectionHeader>
      <ul>
        <li>
          <ListItem>Provide the Imaging Panda Platform to Customer</ListItem>
        </li>
        <li>
          <ListItem>
            Send the imaging center the doctor&rsquo;s order and patient
            information
          </ListItem>
        </li>
        <li>
          <ListItem>
            Send Customer the radiologist&rsquo;s report of the results &nbsp;
          </ListItem>
        </li>
      </ul>
      <br />
      <SectionHeader>Customer Obligations</SectionHeader>
      <ul>
        <li>
          <ListItem>
            Use the Imaging Panda Platform to coordinate care for
            Customer&rsquo;s clients and/or patients for the Permitted Use.
          </ListItem>
        </li>
      </ul>
      <SectionHeader>
        Fax Substitution and Consent to Contact on Customer&apos;s Behalf
      </SectionHeader>
      <Typography textAlign="left">
        Customer agrees that Imaging Panda may substitute a fax number provided
        by Customer with an Imaging Panda fax number so that a Prior
        Authorization (PA) determination and radiology report can be faxed to
        Imaging Panda and delivered as part of the Imaging Panda Services.
      </Typography>
      <Typography textAlign="left">
        Imaging Panda may accept other communications that are unrelated to the
        PAs and radiology reports that Customer has submitted using the Imaging
        Panda Services via the substituted Imaging Panda fax number that a
        health plan, Radiology Benefit Manager (RBM), other payor, or Imaging
        Center may send to Customer. Imaging Panda will make commercially
        reasonable efforts to forward such unrelated communications to Customer
        if Imaging Panda is able to determine, with reasonable certainty, that
        they are intended for Customer. However, Imaging Panda disclaims any
        responsibility for failure to deliver any communications which a health
        plan, RBM, other payor, or Imaging Center transmits to Imaging Panda
        that are unrelated to the requests Customer submits while using the
        Imaging Panda Services and which Imaging Panda has delivered on
        Customer&apos;s behalf.
      </Typography>
      <Typography textAlign="left">
        Customer agrees that Imaging Panda may contact (i) health care
        providers, (ii) health plans, RBMs and other payors, and (iii) imaging
        centers on Customer&apos;s behalf in connection with PA requests, or
        other services, or both.
      </Typography>
      <Typography textAlign="left">
        Imaging Panda may also contact Customer&apos;s patients in connection
        with Imaging Panda Services either on Customer&apos;s behalf as directed
        by Customer or pursuant to an authorization from such patients. Customer
        agrees that, for this purpose, Imaging Panda may use the contact
        information for patients that Customer has provided to Imaging Panda.
      </Typography>
      <Typography textAlign="left" fontWeight="bold">
        *** YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT,
        AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. ***
      </Typography>
    </PageContainer>
  );
}
