import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import { StatusChip, UrgencyChip } from "../Chips";
import { getLocaleDateTimeString } from "utils/parsing";
import StatusHeader from "./StatusHeader";

function EnhancedTableHead(props) {
  const { statusFilter, setStatusFilter } = props;

  const headCells = [
    {
      id: "updated_at",
      numeric: false,
      label: "Last Updated",
    },
    {
      id: "patient",
      numeric: false,
      label: "Patient Name",
    },
    {
      id: "status",
      numeric: false,
      label: (
        <StatusHeader
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
      ),
    },
    {
      id: "urgency",
      numeric: false,
      label: "Urgency",
    },
    {
      id: "facility",
      numeric: false,
      label: "Facility",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sx={{ fontWeight: "bold" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OrdersTable(props) {
  const {
    count,
    orders,
    page: offByOnePage,
    handleChangePage,
    statusFilter,
    setStatusFilter,
  } = props;

  let navigate = useNavigate();
  const rowsPerPage = 25;
  const page = offByOnePage - 1;

  return (
    <Box>
      <Paper variant="outlined" sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
            />
            <TableBody>
              {orders.map((order, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={() => navigate(`/orders/${order.id}?tab=details`)}
                    tabIndex={-1}
                    key={order.id}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{ minWidth: "250px" }}
                    >
                      {getLocaleDateTimeString(order.updated_at)}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{ minWidth: "150px" }}
                    >
                      {order.patient?.name}
                    </TableCell>
                    <TableCell align="left">
                      <StatusChip
                        status={order.status}
                        statusMessage={order.status_message}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <UrgencyChip urgency={order.urgency} />
                    </TableCell>
                    <TableCell align="left">{order?.facility?.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
