import { Box } from "@mui/material";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    value === index && (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        <Box sx={{ py: 0 }}>{children}</Box>
      </div>
    )
  );
}
