import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Stack, Button, Typography, Divider } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import useOrdersAPI from "api/OrdersAPI";
import FacilityExplorerViewer from "components/FacilityExplorer/FacilityExplorerViewer";
import FacilityFilters from "components/FacilityExplorer/FacilityFilters";
import StatusStepper from "components/SubmitOrder/Stepper";
import StartSearch from "components/FacilityExplorer/StartSearch";
import useScrollToRef from "utils/useScrollToRef";

function Results(props) {
  const {
    zipcode,
    facilities,
    searchParams,
    updateSearchParams,
    handleBack,

    /** Override default facility select handler */
    handleFacilitySelect = undefined,
  } = props;
  const [selectedFacility, setSelectedFacility] = React.useState(null);
  const [anchorRef, scrollToRef] = useScrollToRef();
  const navigate = useNavigate();

  const handleFacilityChange = (facility) => {
    setSelectedFacility(facility);

    /* Scroll bottom buttons into view */
    scrollToRef();
  };

  const onFacilitySelect = async () => {
    if (handleFacilitySelect) {
      await handleFacilitySelect(selectedFacility?.id);
      return;
    }

    navigate(`/facilities/${selectedFacility?.id}/submit-order`);
  };

  return (
    <>
      <FacilityFilters
        zipcode={zipcode}
        count={facilities?.count}
        updateSearchParams={updateSearchParams}
      />
      <Divider />
      {facilities?.count > 0 && (
        <FacilityExplorerViewer
          facilities={facilities}
          updateSearchParams={updateSearchParams}
          searchPage={searchParams.page}
          setSelectedFacility={handleFacilityChange}
          selectedFacility={selectedFacility}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <Button onClick={handleBack}>Back</Button>
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          color="primary"
          sx={{ width: "300px", alignSelf: "end" }}
          onClick={onFacilitySelect}
          disabled={!selectedFacility}
        >
          Send Order to Facility
        </Button>
      </Stack>
      <span ref={anchorRef} />
    </>
  );
}

function FacilityExplorer({
  showStatusStepper = true,

  /** Allow user to skip facility selection */
  allowSkip = true,

  /** Override default facility select handler */
  onFacilitySelect = null,
}) {
  const [facilities, setFacilities] = React.useState({});
  const [preferredFacilities, setPreferredFacilities] = React.useState({});
  const [showSearchResults, setShowSearchResults] = React.useState(false);

  const initialState = { nearby: "", name: "", page: 1 };
  const [searchParams, updateSearchParams] = React.useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );
  const { search_facilities, get_preferred_facilities } = useOrdersAPI();

  React.useEffect(() => {
    search_facilities(searchParams, (data) => {
      setFacilities(data);
      if (searchParams.nearby) {
        setShowSearchResults(true);
      }
    });
  }, [search_facilities, searchParams]);

  React.useEffect(() => {
    get_preferred_facilities(setPreferredFacilities);
  }, [get_preferred_facilities]);

  return (
    <Container
      sx={{
        width: "80%",
        "@media (min-height:780px)": { paddingTop: "64px" },
      }}
    >
      <Stack
        spacing={4}
        sx={{ padding: "16px", marginBottom: "32px", marginTop: "32px" }}
      >
        {showStatusStepper && <StatusStepper status={0} />}
        <Typography variant="h4">
          Select a diagnostic imaging facility
        </Typography>
        <Divider />
        {showSearchResults ? (
          <Results
            zipcode={searchParams.nearby}
            facilities={facilities}
            searchParams={searchParams}
            updateSearchParams={updateSearchParams}
            handleBack={() => {
              updateSearchParams({ nearby: "" });
              setShowSearchResults(false);
            }}
            handleFacilitySelect={onFacilitySelect}
          />
        ) : (
          <StartSearch
            updateSearchParams={updateSearchParams}
            preferredFacilities={preferredFacilities}
            setShowSearchResults={setShowSearchResults}
            allowSkip={allowSkip}
            handleFacilitySelect={onFacilitySelect}
          />
        )}
      </Stack>
    </Container>
  );
}

export default FacilityExplorer;
