import { Container, Stack, Typography, Divider } from "@mui/material";

export default function PageContainer(props) {
  const { children, header } = props;
  return (
    <Container
      sx={{ width: "100%", paddingBottom: "64px", paddingLeft: "0px" }}
    >
      <Stack
        spacing={2}
        sx={{
          "@media (min-height:780px)": { marginTop: "128px" },
          marginTop: "32px",
        }}
      >
        <Typography variant="h4" component="div" textAlign="left">
          {header}
        </Typography>
        <Divider />
        {children}
      </Stack>
    </Container>
  );
}
