/**
 * Get full path stored in `location.state.from` if exists. Defaults to "/"
 *
 * @param {Object} location
 * @returns {string}
 */
export function getPrevAuthenticatedPath(location) {
  if (!location?.state?.from) {
    return "/";
  }

  const { pathname, search } = location?.state?.from;

  return pathname + search;
}
