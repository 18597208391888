import React from "react";
import useAxios from "utils/useAxios";

const useOrdersAPI = () => {
  let _ax = useAxios();

  // prettier-ignore
  let API = {
    get_all_orders:             (params, cb)                      => get_all_orders(_ax, params, cb),
    get_all_patients:           (cb)                              => get_all_patients(_ax, cb),
    get_order:                  (orderId, cb)                     => get_order(_ax, orderId, cb),
    get_patient_orders:         (patientId, cb)                   => get_patient_orders(_ax, patientId, cb),
    get_compatible_facilities:  (orderId, cb)                     => get_compatible_facilities(_ax, orderId, cb),
    get_carriers:               (cb)                              => get_carriers(_ax, cb),
    cancel_order:               (orderId, cb)                     => cancel_order(_ax, orderId, cb),
    delete_order:               (orderId, cb)                     => delete_order(_ax, orderId, cb),
    get_imaging_studies:        (cb)                              => get_imaging_studies(_ax, cb),
    create_order:               (order)                           => create_order(_ax, order),
    set_order_facility:         (orderId, facilityId)             => set_order_facility(_ax, orderId, facilityId),
    search_facilities:          (params, cb)                      => search_facilities(_ax, params, cb),
    get_facility:               (facilityId, cb)                  => get_facility(_ax, facilityId, cb),
    get_preferred_facilities:   (cb)                              => get_preferred_facilities(_ax, cb),
    start_file_attachment:      (orderId, cb)                     => start_file_attachment(_ax, orderId, cb),
    upload_attachment:          (orderId, attachmentId, file, cb) => upload_attachment(_ax, orderId, attachmentId, file, cb),
    send_fax:                   (orderId)                         => send_fax(_ax, orderId),
    get_all_users:              ()                                => get_all_users(_ax),
    get_my_user:                ()                                => get_my_user(_ax),
    delete_user:                (userId)                          => delete_user(_ax, userId),
    create_user:                (username, email)                 => create_user(_ax, username, email),
    get_user_logs:              (params)                          => get_user_logs(_ax, params),
    get_order_logs:             (params)                          => get_order_logs(_ax, params),
    get_order_logs_by_id:       (orderId, params)                 => get_order_logs_by_id(_ax, orderId, params),
    share_order:                (orderId, email)                  => share_order(_ax, orderId, email),
    change_password:            (userId,data)                     => change_password(_ax, userId, data),
    send_chat_message:          (orderId, message)                => send_chat_message(_ax, orderId, message),
  };

  return React.useMemo(
    () => API,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default useOrdersAPI;

//===========================================================================//
//                             API HANDLERS
//===========================================================================//

function get_all_orders(axios, params) {
  return axios.get(`/v0/orders/`, { params }).then((res) => res.data);
}

function get_all_patients(axios, callback) {
  axios.get(`/v0/orders/patients/`).then((response) => callback(response.data));
}

function get_order(axios, orderId, callback) {
  axios
    .get(`/v0/orders/${orderId}/`)
    .then((response) => callback(response.data));
}

function get_patient_orders(axios, patientId, callback) {
  axios
    .get(`/v0/orders/patients/${patientId}/`)
    .then((response) => callback(response.data));
}
function get_compatible_facilities(axios, orderId, callback) {
  axios
    .get(`/v0/orders/${orderId}/facilities/`)
    .then((response) => callback(response.data));
}

function get_carriers(axios, callback) {
  axios
    .get(`/v0/orders/insurance/carriers/`)
    .then((response) => callback(response.data));
}

function delete_order(axios, orderId, callback) {
  axios.delete(`/v0/orders/${orderId}/`).then(() => callback());
}

function get_imaging_studies(axios, callback) {
  axios.get(`/v0/orders/studies/`).then((response) => callback(response.data));
}

/**
 * @returns Promise
 */
function create_order(axios, order) {
  return axios.post(`/v0/orders/`, order).then((response) => response.data);
}

/**
 * @returns Promise
 */
function set_order_facility(axios, orderId, facilityId) {
  return axios
    .put(`/v0/orders/${orderId}/`, { facility_id: facilityId })
    .then((response) => response.data);
}

function search_facilities(axios, params, callback) {
  axios
    .get(`/internal/facilities/`, { params })
    .then((response) => callback(response.data));
}

function get_facility(axios, facilityId, callback) {
  axios
    .get(`/internal/facilities/${facilityId}`)
    .then((response) => callback(response.data));
}

function get_preferred_facilities(axios, callback) {
  axios
    .get(`/internal/facilities/preferred/`)
    .then((response) => callback(response.data));
}

function start_file_attachment(axios, orderId, callback) {
  axios
    .post(`/v0/orders/${orderId}/attachments/`)
    .then((res) => callback(res.data));
}

function upload_attachment(axios, orderId, attachmentId, file, callback) {
  let headers = {
    "Content-Disposition": `attachment; filename=${file.name}`,
    "Content-Type": "application/pdf",
  };

  axios
    .put(`/v0/orders/${orderId}/attachments/${attachmentId}/upload/`, file, {
      headers,
    })
    .then((res) => callback(res.data));
}

function cancel_order(axios, orderId, callback) {
  axios
    .put(`/v0/orders/${orderId}/`, { status: "Cancelled" })
    .then((response) => callback(response.data));
}

/**
 * @returns Promise
 */
function send_fax(axios, orderId) {
  return axios
    .post(`/v0/orders/${orderId}/fax/`)
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function get_all_users(axios) {
  return axios.get(`/users/`).then((response) => response.data);
}

/**
 * @returns Promise
 */
function get_my_user(axios) {
  return axios.get(`/account/`).then((response) => response.data);
}

/**
 * @returns Promise
 */
function delete_user(axios, userId) {
  return axios.delete(`/users/${userId}`).then((response) => response.data);
}

/**
 * @returns Promise
 */
function create_user(axios, username, email) {
  return axios
    .post(`/users/`, { username, email })
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function get_user_logs(axios, params) {
  return axios
    .get(`/v0/logs/users/`, { params })
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function get_order_logs(axios, params) {
  return axios
    .get(`/v0/logs/orders/`, { params })
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function get_order_logs_by_id(axios, orderId, params) {
  return axios
    .get(`/v0/logs/orders/${orderId}/`, { params })
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function share_order(axios, orderId, email) {
  return axios
    .post(`/v0/orders/${orderId}/share/`, { email })
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function change_password(axios, userId, data) {
  return axios
    .put(`/users/${userId}/change-password/`, data)
    .then((response) => response.data);
}

/**
 * @returns Promise
 */
function send_chat_message(axios, orderId, message) {
  return axios
    .post(`/v0/orders/${orderId}/discussion/`, { message })
    .then((response) => response.data);
}
