import React from "react";
import {
  Button,
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Table,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";

import { getLocaleDateTimeString } from "utils/parsing";
import ConfirmationDialog from "components/ConfirmationDialog";
import useOrdersAPI from "api/OrdersAPI";
import { reset_password } from "api/account";

const TableCellHeader = styled(TableCell)({
  align: "left",
  fontWeight: "bold",
});

function UsersTable(props) {
  const { me, users, setBanner, refreshUsers } = props;

  const [dialog, setDialog] = React.useState({ open: false });
  const { delete_user } = useOrdersAPI();

  const handleCloseDialog = () => setDialog({ open: false });

  const resetDialogProps = {
    open: true,
    title: "Reset password?",
    text: "The user will receive an email with a link to reset their password.",
    confirmText: "Reset Password",
    handleClose: handleCloseDialog,
  };

  const deleteDialogProps = {
    open: true,
    title: "Delete this user?",
    text: "The user will be permanently lose access to Imaging Panda.",
    confirmText: "Delete User",
    handleClose: handleCloseDialog,
  };

  const resetPassword = (email) => {
    reset_password(email, (error) => {
      handleCloseDialog();
      if (error) {
        setBanner({ message: error.message, severity: "error" });
      } else {
        setBanner({
          message: "Reset password link has been sent!",
          severity: "success",
        });
      }
    });
  };

  const deleteUser = (userId) => {
    delete_user(userId).then(() => {
      handleCloseDialog();
      setBanner({
        message: "User successfully deleted",
        severity: "success",
      });
      refreshUsers();
    });
  };

  return (
    <>
      <Paper variant="outlined">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCellHeader>Username</TableCellHeader>
                <TableCellHeader>Email</TableCellHeader>
                <TableCellHeader>Role</TableCellHeader>
                <TableCellHeader>Last Login</TableCellHeader>
                {me.is_superuser && (
                  <>
                    <TableCellHeader sx={{ minWidth: "250px" }} />
                    <TableCellHeader sx={{ minWidth: "180px" }} />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.results.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.username}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.is_superuser
                      ? "Admin"
                      : user.groups.length === 0
                      ? "Restricted"
                      : "Member"}
                  </TableCell>
                  <TableCell sx={{ minWidth: "250px" }}>
                    {getLocaleDateTimeString(user.last_login)}
                  </TableCell>
                  {me.is_superuser && (
                    <>
                      <TableCell>
                        <Button
                          startIcon={<AutorenewIcon />}
                          color="neutral"
                          onClick={() =>
                            setDialog({
                              ...resetDialogProps,
                              handleConfirm: () => resetPassword(user.email),
                            })
                          }
                        >
                          Reset Password
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        {!user.is_superuser && (
                          <Button
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={() =>
                              setDialog({
                                ...deleteDialogProps,
                                handleConfirm: () => deleteUser(user.id),
                              })
                            }
                          >
                            Delete User
                          </Button>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmationDialog {...dialog} />
    </>
  );
}
export default UsersTable;
