import React from "react";
import { Button } from "@mui/material";
import Icon from "@mui/icons-material/AutorenewRounded";
import { keyframes } from "@mui/system";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default function LoadingButton(props) {
  let { variant, text, loading, loadingText, color, sx, ...rest } = props;

  return (
    <Button
      variant={variant}
      disabled={loading}
      color={color}
      sx={sx}
      {...rest}
    >
      {loading && (
        <Icon sx={{ mr: 1, animation: `${spin} 1s infinite linear` }} />
      )}
      {loading ? loadingText || text : text}
    </Button>
  );
}
