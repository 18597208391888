import React, { useState } from "react";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import StatusStepper from "components/OrdersProfile/StatusStepper";
import { OrderMeta } from "components/OrdersProfile/OrderMeta";
import OrderTabs from "components/OrdersProfile/Tabs";
import useOrdersAPI from "api/OrdersAPI";
import CancelButton from "components/OrdersProfile/CancelButton";
import ResendFaxButton from "components/buttons/ResendFaxButton";
import ShareButton from "components/OrdersProfile/ShareButton";
import Alert from "@mui/material/Alert";

function OrderProfile() {
  const [order, setOrder] = useState({});
  const [banner, setBanner] = useState({ message: "", severity: "success" });

  const { get_order } = useOrdersAPI();
  let { orderId } = useParams();

  const primary_status = order?.status?.split(":")[0];

  React.useEffect(() => {
    get_order(orderId, setOrder);
  }, [orderId, get_order, banner]);

  let FeedbackBanner = () => (
    <Alert
      severity={banner.severity}
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
      }}
    >
      {banner.message}
    </Alert>
  );

  return (
    Object.keys(order).length > 0 && (
      <>
        {banner.message && <FeedbackBanner />}
        <Container
          sx={{
            width: "80%",
            marginTop: "64px",
            position: "relative",
            padding: "0px !important",
          }}
        >
          <StatusStepper
            status={order.status}
            statusMessage={order.status_message || ""}
          />
          <Stack mt={8} direction="row" justifyContent="space-between">
            <Stack spacing={1}>
              {(primary_status === "Cancelled" ||
                primary_status === "Inactive") && (
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  align="left"
                  color={primary_status === "Cancelled" ? "error" : "gray"}
                >
                  {primary_status.toUpperCase()}
                </Typography>
              )}
              <Typography variant="h4">Order Summary</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="end" mb={1}>
              <ShareButton setBanner={setBanner} />
              <ResendFaxButton order={order} onSuccess={setBanner} />
              <CancelButton orderStatus={order.status} />
            </Stack>
          </Stack>
          <Divider />
          <OrderMeta order={order} />
          <OrderTabs order={order} setBanner={setBanner} />
        </Container>
      </>
    )
  );
}

export default OrderProfile;
