import { useRef } from "react";

/**
 * Scroll to parent element when executeScroll is called
 */
export default function useScrollToRef() {
  const ref = useRef(null);

  const scrollToRef = () => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return [ref, scrollToRef];
}
