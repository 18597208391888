import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOrdersAPI from "../../api/OrdersAPI";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function CancelButton(props) {
  const { orderStatus } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  let urlParams = useParams();
  let navigate = useNavigate();
  let { cancel_order } = useOrdersAPI();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCancelOrder = () => {
    setAnchorEl(null);
    cancel_order(urlParams.orderId, () => navigate("/orders/history"));
  };

  const visible = [
    "Draft",
    "UnderReview",
    "ActionRequired",
    "InProgress",
    "FaxSent",
  ].includes(orderStatus);

  return (
    visible && (
      <div>
        <Button onClick={handleClick} color="error">
          Cancel Order
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{ textAlign: "left", paddingLeft: "260px" }}
          fullWidth
          PaperProps={{ sx: { padding: "16px" } }}
        >
          <DialogTitle>Cancel Order?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              WARNING: Cancelling an order is permanent. You will have to submit
              a new order for this patient once it is cancelled.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Go Back</Button>
            <Button
              onClick={handleCancelOrder}
              autoFocus
              color="error"
              variant="contained"
            >
              Yes, Cancel Order
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
}
