import { Stack, Alert, Stepper, Typography } from "@mui/material";
import { Step, StepLabel } from "../Stepper";

export default function StatusStepper(props) {
  const { status, statusMessage } = props;
  const steps = [
    "Draft",
    "Under Review",
    "Fax Sent",
    "Fulfillment In Progress",
    "Results Ready",
  ];
  const translation = {
    Cancelled: -1,
    Draft: 0,
    UnderReview: 1,
    FaxSent: 2,
    InProgress: 3,
    ReportReady: 4,
    Inactive: -1,
    "Inactive:PatientUnreachable": -1,
    "Inactive:PatientNoShow": -1,
    "InProgress:Scheduled": 3,
    "ReportReady:Partial": 4,
    "ReportReady:DirectToReferrer": 4,
    "Cancelled:PatientDeclined": -1,
  };
  const activeStep = translation[status];

  const isStepFailed = (step) => {
    return step === 0 && status === "ActionRequired";
  };

  return (
    <Stack spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          const labelProps = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Action Required
              </Typography>
            );

            labelProps.error = true;
          }

          return (
            <Step key={label} completed={!!(index < activeStep)}>
              <StepLabel {...labelProps} color="inherit">
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {statusMessage && (
        <Alert
          severity={status === "ActionRequired" ? "error" : "info"}
          icon={false}
          sx={{ whiteSpace: "pre-line", textAlign: "left" }}
        >
          {statusMessage}
        </Alert>
      )}
    </Stack>
  );
}
