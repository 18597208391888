import React from "react";
import { IconButton, Tooltip, ClickAwayListener, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import FilterStatusCheckbox from "./FilterStatusCheckbox";

const StatusFilterTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "gray",
    fontSize: "14px",
    textAlign: "left",
    whiteSpace: "pre-line",
    maxWidth: "500px",
  },
}));

export default function StatusHeader(props) {
  const { statusFilter, setStatusFilter } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Stack direction="row" alignItems="center">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <StatusFilterTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="left"
            title={
              <FilterStatusCheckbox
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
            }
          >
            <IconButton onClick={handleTooltipOpen}>
              <FilterListIcon color={statusFilter ? "primary" : ""} />
            </IconButton>
          </StatusFilterTooltip>
        </div>
      </ClickAwayListener>
      <span>Status</span>
    </Stack>
  );
}
