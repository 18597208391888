import { axiosBasic as API } from "utils/useAxios";

export async function sign_user_in(username, password) {
  return API.post("/v0/token/", { username, password }).then(
    (response) => response.data
  );
}

export async function refresh_tokens(token) {
  return API.post("/v0/token/refresh/", { refresh: token }).then(
    (res) => res.data
  );
}

export function set_password(token, password, callback) {
  API.post(
    "/account/set-password/",
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => callback(null, response.data))
    .catch(function (error) {
      if (error?.response?.status === 401) {
        callback(new Error("Token has expired or is invalid."));
      } else if (error?.response?.status === 400) {
        callback(new Error(error.response.data.messages[0]));
      }
    });
}

export function reset_password(email, callback) {
  API.post("/users/reset-password/", { email })
    .then((response) => callback(null, response.data))
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 400) {
          callback(new Error(error.response.data.message));
        }
      }
    });
}

export async function get_my_user(token) {
  return API.get("/account/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.data);
}
