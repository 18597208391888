import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import useOrdersAPI from "../../../api/OrdersAPI";
import LoadingButton from "../../../components/buttons/LoadingButton";

export function allowResendFax(orderStatus) {
  return ["FaxSent", "InProgress"].includes(orderStatus);
}

export default function ResendFaxButton({ order, onSuccess }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(null);
  let { send_fax } = useOrdersAPI();

  let handleDialogButtonClick = () => {
    setLoadingTimer(true);

    send_fax(order.id)
      .then(() => {
        setLoadingTimer(null);
        setOpenDialog(false);
        onSuccess({
          message:
            "Successfully resent referral documents to the imaging facility",
          severity: "success",
        });
      })
      .catch(() => {
        setLoadingTimer(null);
        setOpenDialog(false);
        onSuccess({
          message:
            "There was an error sending referral documents. Please try again later.",
          severity: "error",
        });
      });
  };

  return (
    allowResendFax(order.status) && (
      <div>
        <Button onClick={() => setOpenDialog(true)} color="primary">
          Resend Fax
        </Button>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          sx={{ textAlign: "left", paddingLeft: "260px" }}
          fullWidth
          PaperProps={{ sx: { padding: "16px" } }}
        >
          <DialogTitle>Resend Fax to the Imaging Facility?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Resend your documents to the imaging facility, in case they need
              them again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <LoadingButton
              loading={!!loadingTimer}
              text="Yes, Resend Fax"
              loadingText="Resending Fax..."
              color="primary"
              variant="contained"
              onClick={handleDialogButtonClick}
              autoFocus
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  );
}
