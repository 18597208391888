import React from "react";
import { Button, Box, TextField, Typography, colors } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import useOrdersAPI from "api/OrdersAPI";
import { useAuthContext } from "context/AuthContext";
import Copyright from "components/Copyright";
import PageContainer from "components/PageContainer";
import FeedbackBanner from "components/FeedbackBanner";

function ChangePassword() {
  const [banner, setBanner] = React.useState({
    message: "",
    severity: "success",
  });
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const { change_password } = useOrdersAPI();
  const { user } = useAuthContext();

  const onSubmit = (data) => {
    change_password(user.id, data)
      .then(() => {
        reset();
        setBanner({
          message: "Password changed successfully!",
          severity: "success",
        });
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        for (let err in errorResponse) {
          let details = errorResponse[err];
          let message = "";
          if (Array.isArray(details)) {
            message = details[0];
          } else {
            message = details[err];
          }
          setError(err, { message });
        }
      });
  };

  return (
    <PageContainer header={"Change Password"}>
      <FeedbackBanner banner={banner} />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <Controller
            name="old_password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="Old Password"
                type="password"
                helperText={fieldState.error?.message}
                autoComplete="new-password"
                autoFocus
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="New Password"
                type="password"
                helperText={fieldState.error?.message}
                autoComplete="new-password"
              />
            )}
          />
          <Controller
            name="password2"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: {
                matches: (confirm) => confirm === getValues("password"),
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                helperText={
                  fieldState.error?.type === "matches"
                    ? "Passwords do not match"
                    : ""
                }
              />
            )}
          />
          {errors.non_field_errors && (
            <Typography variant="caption" color={colors.red[500]}>
              {errors.non_field_errors.message}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => clearErrors("non_field_errors")}
          >
            Change Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </PageContainer>
  );
}

export default ChangePassword;
