import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import { Box, Stack } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import HistoryIcon from "@mui/icons-material/History";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Link } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import AddBoxIcon from "@mui/icons-material/AddBox";

function Sidebar() {
  const { signOut, isRestricted } = useAuthContext();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const topItems = [
    {
      title: "Submit New Order",
      icon: <AddBoxIcon />,
      path: "/",
      restricted: true,
    },
    {
      title: "Order History",
      icon: <HistoryIcon />,
      path: "/orders/history",
      restricted: false,
    },
    {
      title: "Recent Activity",
      icon: <RssFeedIcon />,
      path: "/recent",
      restricted: false,
    },
  ];

  const bottomItems = [
    {
      title: "Change Password",
      icon: <LockResetIcon />,
      path: "/account/change-password",
      restricted: false,
    },
    {
      title: "Manage Users",
      icon: <PeopleIcon />,
      path: "/users",
      restricted: true,
    },
    {
      title: "Sign Out",
      icon: <LogoutIcon style={{ transform: "rotate(180deg)" }} />,
      onClick: (e) => {
        e.preventDefault();
        signOut();
      },
      path: "",
      restricted: false,
    },
  ];

  let listItemStyle = {
    color: "#fff",
    opacity: 0.9,
    pl: 2,
    pr: 4,
    py: 1,
  };

  return (
    <Drawer variant="permanent" anchor="left" sx={{ bgColor: "#001628" }}>
      <Box id="sidebar__logo-container" bgcolor="#001628" sx={{ py: 9 }}>
        <Stack alignItems="center">
          <img
            src="/256-framed-panda.png"
            alt="logo"
            width={75}
            style={{ borderRadius: "50%", marginBottom: 30 }}
          />
          <img
            src="https://host-assets.s3.us-west-2.amazonaws.com/public/logo/panda-logo.svg"
            alt="logo"
            width={175}
          />
          {/* <img src="/customer_logos/ih.svg" alt="logo" /> */}
        </Stack>
      </Box>
      <Stack
        justifyContent="space-between"
        color={"grey"}
        bgcolor={"#001628"}
        sx={{ height: "100%" }}
      >
        <List>
          {topItems.map((item, idx) => {
            if (item.restricted && isRestricted()) {
              return null;
            }
            return (
              <ListItemButton
                key={item.title}
                component={Link}
                to={item.path}
                selected={idx === selectedIndex}
                onClick={() => setSelectedIndex(idx)}
                sx={listItemStyle}
              >
                <ListItemIcon sx={listItemStyle}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            );
          })}
        </List>
        <Box>
          <Divider />
          <List>
            {bottomItems.map((item) => {
              if (item.restricted && isRestricted()) {
                return null;
              }
              return (
                <ListItemButton
                  key={item.title}
                  component={Link}
                  to={item.path}
                  onClick={item.onClick}
                  sx={listItemStyle}
                >
                  <ListItemIcon sx={listItemStyle}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
}

export default Sidebar;
