import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { track } from "@amplitude/analytics-browser";

import { useAuthContext } from "context/AuthContext";
import Dashboard from "pages/Dashboard";
import Patients from "pages/Patients";
import PatientOrders from "pages/PatientOrders";
import OrderProfile from "pages/OrderProfile";
import AssignFacility from "pages/AssignFacility";
import SetPassword from "pages/Account/SetPassword";
import ResetPassword from "pages/Account/ResetPassword";
import ChangePassword from "pages/Account/ChangePassword";
import Login from "pages/Login";
import FacilityExplorer from "pages/FacilityExplorer";
import UploadFiles from "pages/UploadFiles";
import UserManagement from "pages/UserManagement";
import RecentActivity from "pages/RecentActivity";
import { getPrevAuthenticatedPath } from "./helpers";
import BAA from "pages/Compliance/BAA";
import TermsOfService from "pages/Compliance/TermsOfService";

const Authenticated = ({ children }) => {
  /*
   * If use is not authenticated, redirect the user to /login page and
   * store the exisitng location to state. This allows the user to return
   * to previous page before being re-routed to /login.
   */
  const { isAuthenticated, user } = useAuthContext();
  const location = useLocation();

  React.useEffect(() => {
    track({
      event_type: "Route Change",
      event_properties: location,
      user_properties: user,
      user_id: user?.id,
    });
  }, [location, user]);

  return !isAuthenticated() ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    children
  );
};

const Unauthenticated = ({ children }) => {
  /*
   * If use is already authenticated, redirect the user to the previous
   * authenticated route
   */
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();

  return !isAuthenticated() ? (
    children
  ) : (
    <Navigate to={getPrevAuthenticatedPath(location)} replace />
  );
};

const AppRoutes = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <Unauthenticated>
          <Login />
        </Unauthenticated>
      }
    />

    {/* Authorized Routes */}
    <Route
      path="patients"
      element={
        <Authenticated>
          <Patients />
        </Authenticated>
      }
    />
    <Route
      path="patients/:patientId/orders"
      element={
        <Authenticated>
          <PatientOrders />
        </Authenticated>
      }
    />
    <Route
      path="facilities/:facilityId/submit-order"
      element={
        <Authenticated>
          <UploadFiles />
        </Authenticated>
      }
    />
    <Route
      path="orders/:orderId/assign-facility"
      element={
        <Authenticated>
          <AssignFacility />
        </Authenticated>
      }
    />
    <Route
      path="/orders/history"
      element={
        <Authenticated>
          <Dashboard />
        </Authenticated>
      }
    />
    <Route
      path="orders/:orderId"
      element={
        <Authenticated>
          <OrderProfile />
        </Authenticated>
      }
    />
    <Route
      path="users"
      element={
        <Authenticated>
          <UserManagement />
        </Authenticated>
      }
    />
    <Route
      path="recent"
      element={
        <Authenticated>
          <RecentActivity />
        </Authenticated>
      }
    />
    <Route
      path="baa"
      element={
        <Authenticated>
          <BAA />
        </Authenticated>
      }
    />
    <Route
      path="tos"
      element={
        <Authenticated>
          <TermsOfService />
        </Authenticated>
      }
    />
    <Route
      path="account/change-password"
      element={
        <Authenticated>
          <ChangePassword />
        </Authenticated>
      }
    />
    <Route path="account/set-password" element={<SetPassword />} />
    <Route path="account/reset-password" element={<ResetPassword />} />

    {/** Catch-all route navigates to Submit New Order workflow */}
    <Route
      path="*"
      element={
        <Authenticated>
          <FacilityExplorer />
        </Authenticated>
      }
    />
  </Routes>
);

export default AppRoutes;
