/**
 * @param {number} date
 * @returns {string}
 */
export const getLocaleDateTimeString = (date) => {
  if (!date) {
    return "-";
  }
  let dt = new Date(date);
  return `${dt.toDateString()}, ${dt.toLocaleTimeString()}`;
};
