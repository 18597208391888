import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import { FacilitySelector } from "../../components/AssignFacility";
import useOrdersAPI from "../../api/OrdersAPI";

function AssignFacility() {
  const [order, setOrder] = React.useState({});
  const [facilities, setFacilities] = React.useState();
  const { get_order, get_compatible_facilities } = useOrdersAPI();
  let urlParams = useParams();

  React.useEffect(() => {
    get_order(urlParams.orderId, setOrder);
  }, [urlParams.orderId, get_order]);

  React.useEffect(() => {
    get_compatible_facilities(urlParams.orderId, setFacilities);
  }, [urlParams.orderId, get_compatible_facilities]);

  if (facilities === undefined) {
    return null;
  }

  return (
    <Container sx={{ width: "80%", paddingY: "32px" }}>
      <FacilitySelector patient={order?.patient} facilities={facilities} />
    </Container>
  );
}
export default AssignFacility;
