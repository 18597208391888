import React from "react";
import {
  colors,
  Paper,
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { getPrevAuthenticatedPath } from "components/AppRoutes/helpers";
import Copyright from "components/Copyright";

export default function SignIn() {
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuthContext();

  const onSubmit = async (data) => {
    try {
      await signIn(data.username, data.password);

      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(getPrevAuthenticatedPath(location), { replace: true });
    } catch (error) {
      setError("login_failed", { type: "custom", message: error.message });
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, pt: 8 }}>
      <Paper elevation={4} sx={{ p: 4, pb: 6, borderRadius: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ my: 4, mb: 2, bgcolor: colors.blue[200] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" mb={2}>
            Account Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  margin="normal"
                  required
                  fullWidth
                  label="Username"
                  autoComplete="username"
                  autoFocus
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={!!fieldState.error}
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                />
              )}
            />
            {/* TODO
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
              onClick={() => clearErrors("login_failed")}
            >
              Sign In
            </Button>
            {errors.login_failed && (
              <Typography variant="caption" color={colors.red[500]}>
                {errors.login_failed.message}
              </Typography>
            )}
            <Link
              to="/account/reset-password"
              style={{ color: "#6a6a6a", textAlign: "right" }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                align="right"
                mt={2}
              >
                Forgot Password
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
