import React from "react";
import useOrdersAPI from "api/OrdersAPI";
import OrderActivityTable from "components/AuditLogTables/OrderActivityTable";
import PageContainer from "components/PageContainer";

function RecentActivity() {
  const [orderLogsResponse, setOrderLogs] = React.useState({
    count: 0,
    results: [],
  });
  const [page, setPage] = React.useState(1);

  const { get_order_logs } = useOrdersAPI();

  React.useEffect(() => {
    get_order_logs({ page }).then((responseData) => setOrderLogs(responseData));
  }, [get_order_logs, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <PageContainer header="Recent Activity">
      <OrderActivityTable
        orderLogsResponse={orderLogsResponse}
        page={page}
        handleChangePage={handleChangePage}
      />
    </PageContainer>
  );
}
export default RecentActivity;
