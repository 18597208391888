import React from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import PatientsTable from "../../components/PatientsTable";
import useOrdersAPI from "../../api/OrdersAPI";

function Patients() {
  const [patients, setPatients] = React.useState([]);
  const { get_all_patients } = useOrdersAPI();

  React.useEffect(() => {
    get_all_patients(setPatients);
  }, [get_all_patients]);

  return (
    <Container sx={{ width: "80%" }}>
      <Box sx={{ padding: "16px", marginBottom: "32px", marginTop: "32px" }}>
        <Typography variant="h2" component="div">
          Patients
        </Typography>
      </Box>
      <PatientsTable patients={patients} />
    </Container>
  );
}
export default Patients;
