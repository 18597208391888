import PageContainer from "components/PageContainer";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const SectionHeader = styled(Typography)({
  textAlign: "left",
  fontWeight: "bold",
  textDecoration: "underline",
});

const ListItem = styled(Typography)({
  textAlign: "left",
  paddingLeft: 16,
});

export default function BAA() {
  const terms = [
    `“Breach” shall have the same meaning as the term “breach” in 45 C.F.R.164.402.`,
    `“Designated Record Set” shall have the same meaning as the term
    “designated record set” in 45 C.F.R. § 164.501.`,
    `“Electronic Protected Health Information” shall mean Protected Health
    Information that is “electronic protected health information” as defined
    in 45 C.F.R. § 160.103.`,
    `"HHS" shall mean the Department of Health and Human Services.`,
    `"HIPAA" shall mean the Health Insurance Portability and Accountability
    Act of 1996, as well as the Health Information Technology for Economic
    and Clinical Health Act of 2009 ("HITECH"), and the regulations set
    forth at 45 C.F.R. Parts 160-64, each as amended from time to time.`,
    `“Individual” shall have the same meaning as the term “individual” in 45
    C.F.R. §160.103 and shall include a person who qualifies as a personal
    representative in accordance with 45 C.F.R. §164.502(g).`,
    `“Protected Health Information” shall have the same meaning as the term
    “protected health information” in 45 C.F.R. § 160.103, except limited to
    the information received from Covered Entity, or created, maintained or
    received on behalf of Covered Entity.`,
    `“Required By Law” shall have the same meaning as the term “required by
    law” in 45 C.F.R. § 164.103. “Secretary” shall mean the Secretary of HHS
    or the designee of the Secretary of HHS.`,
    `"Services Agreement" shall mean the agreement between Covered Entity and
    Business Associate governing the provision of the Services to Covered
    Entity by Business Associate.`,
    `“Subcontractor” shall have the same meaning as the term “subcontractor”
    in 45 C.F.R. §160.103, except limited to any such individual or entity
    who creates, receives, maintains, or transmits Protected Health
    Information on behalf of Business Associate.`,
    `“Unsecured Protected Health Information” shall mean Protected Health
    Information that is “unsecured protected health information” as defined
    in 45 C.F.R. § 164.402.`,
  ];

  const obligations = [
    "Business Associate agrees to not use or disclose Protected Health Information other than as permitted or required by this Agreement or as Required By Law.",
    "Business Associate agrees to use appropriate safeguards and comply, where applicable, with Subpart C of 45 C.F.R. Part 164 with respect to Electronic Protected Health Information, to prevent use or disclosure of the Protected Health Information other than as provided for by this Agreement.",
    `Business Associate agrees to report to the Covered Entity any use or
    disclosure of Protected Health Information not provided for by this
    Agreement, including, without limitation, Breaches of Unsecured
    Protected Health Information as required at 45 C.F.R. 164.410, and any
    Security Incident of which it becomes aware. The parties acknowledge
    and agree that this Section 2(c) constitutes notice by Business
    Associate to Covered Entity of the ongoing existence and occurrence of
    attempted but unsuccessful Security Incidents for which no additional
    notice to Covered Entity shall be required. Unsuccessful Security
    Incidents shall include, but not be limited to, pings and other
    broadcast attacks on Business Associate’s firewall, port scans,
    unsuccessful log-on attempts, denials of service and any combination
    of the above, so long as such incidents do not result, to the extent
    Business Associate is aware, in unauthorized access, use or disclosure
    of Electronic Protected Health Information. In accordance with 45
    C.F.R. 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, Business
    Associate agrees to ensure that any Subcontractors that create,
    receive, maintain, or transmit Protected Health Information on behalf
    of Business Associate agree in writing to the same restrictions,
    conditions, and requirements that apply to Business Associate under
    this Agreement with respect to such Protected Health Information.`,
    `Business Associate agrees to make available Protected Health
    Information in a Designated Record Set to Covered Entity as necessary
    to satisfy Covered Entity’s obligations under 45 C.F.R. § 164.524.`,
    `Business Associate agrees to make any amendment(s) to Protected Health
    Information in a Designated Record Set as directed or agreed to by the
    Covered Entity pursuant to 45 C.F.R. § 164.526, or take other measures
    as necessary to satisfy Covered Entity’s obligations under 45 C.F.R. §
    164.526.`,
    `Business Associate agrees to maintain and make available the
    information required to provide an accounting of disclosures to
    Covered Entity as necessary to satisfy Covered Entity’s obligations
    under 45 C.F.R. § 164.528. To the extent that Business Associate is to
    carry out one or more of Covered Entity’s obligations under Subpart E
    of 45 C.F.R. Part 164, Business Associate agrees to comply with the
    requirements of Subpart E that apply to Covered Entity in the
    performance of such obligations.`,
    `Business Associate agrees to make its internal practices, books, and
    records available to the Secretary for purposes of determining
    compliance with HIPAA.`,
  ];

  const permissions = [
    `Business Associate may only use or disclose Protected Health
    Information as necessary to perform the Services. In addition,
    Business Associate is authorized to use Protected Health Information
    to de-identify the Protected Health Information in accordance with 45
    C.F.R. 164.514(a)-(c).`,
    `Business Associate may use or disclose Protected Health Information as
    Required By Law.`,
    `Business Associate agrees to make uses and disclosures and requests
    for Protected Health Information consistent with Covered Entity’s
    minimum necessary policies and procedures.`,
    `Business Associate may not use or disclose Protected Health
    Information in a manner that would violate Subpart E of 45 C.F.R. Part
    164 if done by Covered Entity, except for the specific uses and
    disclosures set forth in subsections (e), (f) and (g), below.`,
    `Business Associate may use Protected Health Information for the proper
    management and administration of the Business Associate or to carry
    out the legal responsibilities of the Business Associate.`,
    `Business Associate may disclose Protected Health Information for the
    proper management and administration of the Business Associate or to
    carry out the legal responsibilities of the Business Associate,
    provided the disclosures are Required By Law, or Business Associate
    obtains reasonable assurances from the person to whom the information
    is disclosed that the information will remain confidential and used or
    further disclosed only as Required By Law or for the purposes for
    which it was disclosed to the person, and the person notified Business
    Associate of any instances of which it is aware in which the
    confidentiality of the information has been breached.`,
    `Business Associate may provide Data Aggregation services relating to
    the Health Care Operations of Covered Entity.`,
  ];

  const covered_entity_obligations = [
    `Covered Entity shall notify Business Associate of any limitation(s) in
    the notice of privacy practices of Covered Entity under 45 C.F.R.
    164.520, to the extent that such limitation may affect Business
    Associate’s use or disclosure of Protected Health Information.`,
    `Covered Entity shall notify Business Associate of any changes in, or
    revocation of, the permission by an Individual to use or disclose his
    or her Protected Health Information, to the extent that such changes
    may affect Business Associate’s use or disclosure of Protected Health
    Information.`,
    `Covered Entity shall notify Business Associate of any restriction on
    the use or disclosure of Protected Health Information that Covered
    Entity has agreed to or is required to abide by under 45 C.F.R.
    164.522, to the extent that such restriction may affect Business
    Associate’s use or disclosure of Protected Health Information. Except
    with respect to uses and disclosures by Business Associate of
    Protected Health Information under Sections 3(e), 3(f) and 3(g),
    above, Covered Entity shall not request Business Associate to use or
    disclose Protected Health Information in any manner that would not be
    permissible under Subpart E of 45 C.F.R. Part 164 if done by Covered
    Entity`,
  ];

  const term_and_terminations = {
    Term: `The Term of this Agreement shall commence upon the date of its
    acceptance by Covered Entity (the "Effective Date") and shall
    terminate upon the termination of the Services Agreement or on the
    date Covered Entity terminates this Agreement for cause as authorized
    in subsection (b) of this Section 5, whichever is sooner.`,

    "Termination for Cause": `Business Associate authorizes termination of
    this Agreement by Covered Entity upon written notice to Business
    Associate if Covered Entity determines Business Associate has violated
    a material term of this Agreement and Business Associate has not cured
    the breach or ended the violation within thirty (30) days of Covered
    Entity providing written notice thereof to Business Associate.`,

    "Obligations of Business Associate Upon Termination": `Upon termination
          of this Agreement for any reason, Business Associate shall: (i) Retain
          only that Protected Health Information which is necessary for Business
          Associate to continue its proper management and administration or to
          carry out its legal responsibilities; (ii) Return to Covered Entity or
          Covered Entity’s designee (to the extent permitted by HIPAA), or, if
          agreed to by Covered Entity, destroy the remaining Protected Health
          Information that the Business Associate still maintains in any form;
          (iii) Continue to use appropriate safeguards and comply with Subpart C
          of 45 C.F.R. Part 164 with respect to Electronic Protected Health
          Information to prevent use or disclosure of the Protected Health
          Information, other than as provided for in this Section, for as long
          as Business Associate retains Protected Health Information; (iv) Not
          use or disclose Protected Health Information retained by Business
          Associate other than for the purposes for which such Protected Health
          Information was retained and subject to the same conditions set out at
          Section 3 (e) and (f), above, which applied prior to termination; and
          (v) Return to Covered Entity, or, if agreed to by Covered Entity,
          destroy Protected Health Information retained by Business Associate
          when it is no longer needed by Business Associate for its proper
          management and administration or to carry out its legal
          responsibilities.`,

    Survival:
      "The obligations of Business Associate under this Section 5 shall survive the termination of this Agreement.",
  };

  const misc = {
    "Regulatory References":
      "A reference in this Agreement to a section of HIPAA means the provision as in effect or as amended.",
    Amendment: `The parties agree to take such action as is necessary to
      amend this Agreement from time to time as is necessary for the Covered
      Entity to comply with the requirements of HIPAA and any other
      applicable law.`,
    Interpretation:
      "Any ambiguity in this Agreement shall be resolved to permit compliance with HIPAA.",
    "Governing Law and Disputes": `The construction, interpretation and
      performance of this Agreement and all transactions under this
      Agreement shall be governed and enforced pursuant to the laws of the
      State of California, without giving effect to its conflicts of laws
      provisions, except to the extent California law is preempted by any
      provision of federal law, including HIPAA. The Parties agree that all
      disputes arising out of or relating to this Agreement will be subject
      to mandatory binding arbitration under the rules of Judicial
      Administration and Arbitration Services (“JAMS”) in effect at the time
      of submission, as modified by this Section 6(d). The arbitration will
      be heard and determined by a single arbitrator selected by mutual
      agreement of the Parties, or, failing agreement within thirty (30)
      days following the date of receipt by the respondent of the claim, by
      JAMS. Such arbitration will take place in San Francisco, California.
      The arbitration award so given will be a final and binding
      determination of the dispute, and will be fully enforceable in any
      court of competent jurisdiction. Except in a proceeding to enforce the
      results of the arbitration or as otherwise required by law, neither
      Party nor any arbitrator may disclose the existence, content or
      results of any arbitration hereunder without the prior written
      agreement of both Parties.`,
    "No Third Party Beneficiary": `Nothing express or implied in this
      Agreement is intended to confer, nor shall anything herein confer,
      upon any person other than the parties and the respective successors
      or assigns of the parties, any rights, remedies, obligations, or
      liabilities whatsoever.`,
    "Controlling Provisions": `In the event that it is impossible to comply
      with both the Services Agreement and this Agreement, the provisions of
      this Agreement shall control with respect to those provisions of each
      agreement that expressly conflict. This Agreement shall supersede and
      replace any prior business associate agreements between the parties,
      with respect to any actions of Business Associate after the Effective
      Date.`,
    Effect: `This Agreement shall be binding upon, and shall inure to the
      benefit of, the parties hereto and their respective successors,
      assigns, heirs, executors, administrators and other legal
      representatives.`,
    Severability: `In the event any provision of this Agreement is rendered
      invalid or unenforceable under any new or existing law or regulation,
      or declared null and void by any court of competent jurisdiction, the
      remainder of the provisions of this Agreement shall remain in full
      force and effect if it reasonably can be given effect.`,
  };

  return (
    <PageContainer header="Business Associate Agreement">
      <Box>
        <Typography fontWeight="bold">*** IMPORTANT ***</Typography>
        <Typography fontWeight="bold">
          PLEASE READ CAREFULLY BEFORE YOU USE THE IMAGING PANDA SERVICES
        </Typography>
        <br></br>
        <Typography textAlign="left" fontWeight="bold">
          THIS DOCUMENT (THE “AGREEMENT”) IS A LEGAL AGREEMENT BETWEEN IMAGING
          PANDA, INC. (THE “BUSINESS ASSOCIATE”) AND YOU (THE “COVERED ENTITY”).
        </Typography>
        <br></br>
        <Typography textAlign="left" fontWeight="bold">
          BY USING ANY SERVICES PROVIDED BY BUSINESS ASSOCIATE (THE "SERVICES"),
          YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT AND
          AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS. IF YOU ARE NOT WILLING
          TO BE BOUND BY THE TERMS OF THIS AGREEMENT, DO NOT USE THE SERVICES.
        </Typography>
        <br></br>
        <Typography textAlign="left" fontWeight="bold">
          IF YOU ARE USING THE SERVICES IN YOUR CAPACITY AS AN EMPLOYEE OR AGENT
          OF A COMPANY OR ORGANIZATION, THEN ANY REFERENCES TO THE “COVERED
          ENTITY” IN THIS AGREEMENT SHALL REFER TO SUCH ENTITY AND NOT TO YOU IN
          YOUR PERSONAL CAPACITY. YOU WARRANT THAT YOU ARE AUTHORIZED TO LEGALLY
          BIND THE COVERED ENTITY. IF YOU ARE NOT SO AUTHORIZED, THEN NEITHER
          YOU NOR THE COVERED ENTITY MAY USE THE SERVICES IN ANY MANNER
          WHATSOEVER.
        </Typography>

        <br></br>

        <ol>
          <li>
            <SectionHeader>Definitions:</SectionHeader>
          </li>
          <Typography textAlign="left">
            The following terms shall have the following meaning when used in
            this Agreement:
          </Typography>
          <ol type="a">
            {terms.map((term) => (
              <li>
                <ListItem>{term}</ListItem>
              </li>
            ))}
          </ol>

          <br></br>

          <Typography textAlign="left">
            Any capitalized term not specifically defined herein shall have the
            same meaning as is set forth in 45 C.F.R. Parts 160 and 164, where
            applicable. The terms “use,” “disclose” and “discovery,” or
            derivations thereof, although not capitalized, shall also have the
            same meanings set forth in HIPAA.
          </Typography>

          <br></br>

          <li>
            <SectionHeader>
              Obligations and Activities of Business Associate:
            </SectionHeader>
          </li>
          <ol type="a">
            {obligations.map((obligation) => (
              <li>
                <ListItem>{obligation}</ListItem>
              </li>
            ))}
          </ol>

          <br></br>

          <li>
            <SectionHeader>
              Permitted Uses and Disclosures by Business Associate:
            </SectionHeader>
          </li>
          <ol type="a">
            {permissions.map((permission) => (
              <li>
                <ListItem>{permission}</ListItem>
              </li>
            ))}
          </ol>

          <br></br>

          <li>
            <SectionHeader>Obligations of Covered Entity:</SectionHeader>
          </li>
          <ol type="a">
            {covered_entity_obligations.map((obligation) => (
              <li>
                <ListItem>{obligation}</ListItem>
              </li>
            ))}
          </ol>

          <br></br>

          <li>
            <SectionHeader>Term and Termination:</SectionHeader>
          </li>
          <ol type="a">
            {Object.entries(term_and_terminations).map(([title, data]) => (
              <li>
                <ListItem textAlign="left">
                  <Typography
                    component="span"
                    sx={{ textDecoration: "underline" }}
                  >
                    {title}.
                  </Typography>{" "}
                  {data}
                </ListItem>
              </li>
            ))}
          </ol>

          <br></br>

          <li>
            <SectionHeader>Miscellaneous:</SectionHeader>
          </li>
          <ol type="a">
            {Object.entries(misc).map(([title, data]) => (
              <li>
                <ListItem>
                  <Typography
                    component="span"
                    sx={{ textDecoration: "underline" }}
                  >
                    {title}.
                  </Typography>{" "}
                  {data}
                </ListItem>
              </li>
            ))}
          </ol>
        </ol>

        <Typography fontWeight="bold">
          ***YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT,
          AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.***
        </Typography>
      </Box>
    </PageContainer>
  );
}
