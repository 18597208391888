import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ConfirmationDialog(props) {
  const { open, title, text, confirmText, handleConfirm, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ textAlign: "left" }}
      fullWidth
      PaperProps={{ sx: { padding: "16px" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          color="error"
          variant="contained"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
